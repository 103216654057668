import React, { useEffect, useState, useContext } from 'react';
import axios from '../../../../axios'
import html2canvas from 'html2canvas'
import { DataContext } from '../../../../context/DataContext' 
import { useHistory, useLocation } from 'react-router-dom';
import Loader from '../../../Loader'

const OxxoPay = ({setMetodoPago, pedido}) => {

	const [oxxoResponse, setOxxoResponse] = useState({amount:0,charges:{data:[{payment_method:{reference:''}}]}})
	const [loader, setLoader] = useState(true)
	const { setCarrito } = useContext(DataContext)
	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/micarrito/finaliza' } };


	useEffect(()=>{
		if(pedido.id){
			axios.post('/api/oxxopay',pedido)
			.then(r=>{
				setOxxoResponse(r.data)
				setLoader(false)
			})
			.catch(r=>alert(r))
		}
	},[pedido])

	const _saveImg = () => {

		axios.post('/api/oxxopay_method', {id: pedido.id})
		.then(r=>{
			html2canvas(document.querySelector("#capture"),{allowTaint:true,useCORS:true}).then(canvas => {
				var a = document.createElement('a');
				a.download = 'pago-oxxo.png';
				a.href = canvas.toDataURL()
				a.click()
				a.remove()
				alert('Recuerda que tienes 3 días a partir de hoy para realizar tu pago en OXXO')
				history.replace(from)
			});
		})
		.catch(r=>alert(r))
	}

	if(loader) return <Loader />

	return(
		<div id='capture' style={{padding:15}}>
			<a href='#!' onClick={()=>setMetodoPago('')}>volver</a>
			<center>
				<div style={{background:'black',color:'white', display:'inline-block', padding:10}}>FICHA DIGITAL, NO ES NECESARIO IMPRIMIR</div>
			</center>
			<div className='row' style={{marginTop:30}}>
				<div className='col'>
					<img src='https://raw.githubusercontent.com/conekta-examples/oxxopay-payment-stub/master/demo/opps_en_files/oxxopay_brand.png' />
				</div>
				<div className='col'>
					<div className='grey-text bold'>MONTO A PAGAR</div>
					<h4 className='black-text bold'>${(oxxoResponse.amount/100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h4>
					<p style={{maxWidth:260, color:'grey'}}>OXXO cobrará una comisión adicional al momento de realizar el pago.</p>
					<a href='#!' onClick={()=>_saveImg()} className='btn right black'>Click para Guardar</a>
				</div>
			</div>
			<div className='row'>
				<div className='grey-text bold' style={{fontSize:20}}>REFERENCIA</div>
				<center style={{border:'solid grey 1px', background:'#FAFAFB',borderRadius:5}}>
					<div style={{display:'inline-block', padding:'10px 30px 10px 30px', fontSize:25}}>{(oxxoResponse.charges.data[0].payment_method.reference).replace(/(.{4})/g, '$1-').trim()}</div>
				</center>
			</div>
			<div style={{background:'#F3F3F3',paddingTop:30, paddingBottom:30}}>
				<div>INSTRUCIONES</div>
				<ol>
					<li>Guarda esta ficha.</li>
					<li>Acude a la tienda OXXO más cercana. <a href="https://www.google.com.mx/maps/search/oxxo/" target="_blank">Encuéntrala aquí</a>.</li>
					<li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
					<li>Dicta al cajero el número de referencia en esta ficha para que tecleé directamete en la pantalla de venta.</li>
					<li>Realiza el pago correspondiente con dinero en efectivo.</li>
					<li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
				</ol>
				
				<div style={{border:'solid green 1px', borderRadius:5, padding:10, background:'white', maxWidth:400,textAlign:'center', margin:'auto'}}>Al completar estos pasos recibirás un correo de <strong className='store-name'></strong> confirmando tu pago.</div>
			</div>
		</div>
	)
}

export default OxxoPay
