import React, {useEffect, useState, useContext} from 'react';
import Nav from '../nav';
import { Link } from 'react-router-dom'
import dog from './assets/dog.png'

const Error404 = () => {

		return (
			<div>
				<Nav />
				<center>
				<div style={{display:'inline-block'}}>
					<h3>LO SENTIMOS</h3>
					<h4>
						no pudimos encontrar la página que buscabas
					</h4>
					<p>
						Trada de usar la barra de búsqueda o visita la <Link to='/'> página principal</Link>
					</p>
					<img src={dog} style={{maxWidth:'100%', height:'auto'}}/>
				</div>
				</center>
			</div>
		);
};

export default Error404;
