import React, {useState} from 'react';
import Nav from '../../nav';
import axios from 'axios';
import M from 'materialize-css';

const TrabajoForm = () => {
	const [data, setData] = useState({nombre:"",correo:"",telefono:"",nivel_estudios:"",puesto_solicitado:"",nota:""});

	const sendData = (e) =>{

		e.preventDefault()
		axios.post('/api/trabajaconnosotros',data).then(r=>{
			M.toast({html:"Datos Guardados!!"})
			setData({nombre:"",correo:"",telefono:"",nivel_estudios:"",puesto_solicitado:"",nota:""});
		}).catch((r)=>console.log(r))

	}

	return (
		<div>
		  	<Nav />
			<div className='row m-p-0'>
				<div className='col s12 m-p-0 barra'>
					<div>Trabaja con nosotros: </div>
				</div>
			</div>
			<div className='row center-y-x ptb-80'>
				<div className='col s12 m10 l6 m-p-0 center-y-x'>
					<div className='card-panel'>
						<div className='s12'>
							Si consideras que puedes formar parte del equipo de <span className='store-name'></span> te invitamos a que nos compartas tus datos.
						</div>
						<div className='col s12 pt-10'>
							<form onSubmit={sendData}>
								<div className='input-field col s12 p-0'>
									<input value={data.nombre} onChange={(e)=>setData({...data, nombre: e.target.value})} id='nombre' type='text' className='input-height35 validate'/>
									<label htmlFor='nombre'>Nombre*</label>
								</div>
								<div className='input-field col s12 p-0'>
									<input value={data.correo} onChange={(e)=>setData({...data, correo: e.target.value})} id='correo' type='email' className='input-height35 validate'/>
									<label htmlFor='correo'>Correo*</label>
								</div>
								<div className='input-field col s12 p-0'>
									<input value={data.telefono} onChange={(e)=>setData({...data, telefono: e.target.value})} id='telefono' type='text' className='input-height35 validate'/>
									<label htmlFor='telefono'>Telefono*</label>
								</div>
								<div className='input-field col s12 p-0'>
									<input value={data.nivel_estudios} onChange={(e)=>setData({...data, nivel_estudios: e.target.value})} id='nivel_estudios' type='text' className='input-height35 validate'/>
									<label htmlFor='nivel_estudios'>Nivel estudios*</label>
								</div>
								<div className='input-field col s12 p-0'>
									<input value={data.puesto_solicitado} onChange={(e)=>setData({...data, puesto_solicitado: e.target.value})} id='puesto_solicitado' type='text' className='input-height35 validate'/>
									<label htmlFor='puesto_solicitado'>Puesto Solicitado*</label>
								</div>
								<div className="input-field col s12">
									<textarea value={data.nota} onChange={(e)=>setData({...data, nota: e.target.value})} id="nota" className="materialize-textarea"></textarea>
									<label htmlFor="nora">Nota</label>
								</div>
								<button className='btn primarycolor white-text waves-effect waves-light col s12' >Enviar</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TrabajoForm;
