import React, {useEffect, useState} from 'react';
import './home.css';
import M from 'materialize-css'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import axios from 'axios';
import Loader from '../Loader';

const Banner = () => {
    const [imagenes, setImg] = useState([])
    const [loader, setLoader] = useState(true)

    useEffect(()=>{	
        setLoader(true)
        axios.get('/api/configwebsitesliders')
        .then(r=>{
            setImg(r.data)
            setLoader(false)
        })
        .catch(r=>alert(r))

    },[])

    useEffect(()=>{   
        M.Carousel.init(document.querySelectorAll('.carousel'),{
            indicators:true
        }) 
    },[])

    return (
        <div>
            {loader?
            <Loader />
            :
            <div>
                {imagenes.length >= 1 && 
                <>
                    <Swiper
                        className=""
                        loop={false}
			modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        navigation
                        style={{ marginLeft:'0', marginRight:'0', position: 'unset', position:'relative'}}
                        spaceBetween={1}
                        effect={'fade'}
                        slidesPerView={'auto'}
			autoplay={{delay:5000}}
                        tabIndex="0">
                        {imagenes.filter(i=>i.visible===1).map((p,i)=>
                            <SwiperSlide id="carusel-elementos" key={i} tabIndex="0" style={{margin:'0!important', padding:'0!important',width:'100%'}}>
                                <div className="portada" style={{height:"78vh",}}>   
                                    <div className="back-img center-x-y" href="#one!" style={{background:"url("+p.img+")", height:'100%'}}>
                                        <div className="cont-text-banner"> 
                                            <div className="banner-txt-cont">
                                                <h3 className="banner-subtitle center" style={{color:p.color_txt}}>{p.titulo}</h3>
                                                {p.descripcion &&
                                                <div className="banner-txt" style={{color:p.color_txt, borderTop:'3px solid'+p.color_txt}}>                                            
                                                    {p.descripcion}
                                                </div>
                                                } 
                                                {
                                                    p.visible_btn===1 &&
                                                    <div className="m-p-0 center">
                                                        <a href={p.url_btn} target="_blank" rel="noopener noreferrer" className="btn banner-btn primarycolor">{p.titulo_btn}</a>
                                                    </div>
                                                    
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                    </Swiper>
            </>
                }
            </div>
            }
           
        </div>
    );
};

export default Banner;
