import React, { useContext } from 'react';
import Nav from '../nav';
import Banner from './Banner';
import Productos from './Productos';
import Departament from '../departaments';
import Informacion1 from './Informacion1';
import { DataContext } from '../../context/DataContext';

const Home = () => {
    const {config} = useContext(DataContext)
    
    return (
        <div>
            <Nav />
            <Banner />
            <Productos />
            <Informacion1 img_promo={config.img_promo1} visible={config.visible_promo1}/>
            <Departament /> 
            <div className='espacio'></div>
            <Informacion1 img_promo={config.img_promo2} visible={config.visible_promo2}/>
        </div>
    );
};

export default Home;