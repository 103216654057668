import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import StatusBar from '../statusBar'
import axios from '../../../axios'
import { DataContext } from '../../../context/DataContext'
import Nav from '../../nav'
import Loader from '../../Loader';

const Envio = () => {

	const { carrito, direccion, servicioEnvio, setServicioEnvio, user, config } = useContext(DataContext)
	const weight=carrito.reduce((a,b)=>a+(parseFloat(b.peso_neto)*b.cantidad),0)
	const [packagingServices, setPackaginServices] = useState([])
	const [loader, setLoader] = useState(true)

	useEffect(() => {
		console.log(config.configs)
		console.log(servicioEnvio)
		if(direccion.cp)
			axios.post('/api/packaging/rate-service',{weight,cp:direccion.cp})
			.then(r=>{
				const data = r.data.length>0?r.data:[r.data]
				setPackaginServices(data)
				if(data.some(s=>s.ServiceDescription.ServiceType===servicioEnvio.servicio)===false&&servicioEnvio.servicio!=='Recolección'){
					setServicioEnvio({})
				}
				const s = data.find(s=>s.ServiceDescription.ServiceType===servicioEnvio.servicio)
				if(s){
					//Actualiza la data ejem: no se puede cobrar siempre lo mismo
					setServicioEnvio({proveedor:'Fedex', servicio: s.ServiceDescription.ServiceType, precio:s.RatedShipmentDetails.ShipmentRateDetail.TotalNetCharge.Amount,descripcion:s.ServiceDescription.Names[1].Value,entrega:s.DeliveryTimestamp})
				}
				setLoader(false)
			})
			.catch(r=>alert(r))
			 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[direccion.cp, weight])

	return (
		<>
		{user.name===undefined&&
			<Nav />
		}
		<div className="cart-container-short">
			<div className="center-y-x" style={{backgroundColor:'#ffffff', paddingBottom:'40px'}}>
				<div className="" style={{ width:'50px', marginRight:'50px', display:'inline-block',}}>
					<Link to='/' className=''>
						<img className='logo-img' style={{verticalAlign:'middle', height:'40px', width:'40px'}} alt=''/>
					</Link>
				</div>
				<div className="center-y-x">
					<StatusBar status='envio'/>
				</div>
				<div className="center-y-x" style={{paddingLeft:'50px', display:'inline-block'}}>
					{user.name&&
					<div>
						<Link  className='link-a black-text' to='/miperfil'>
							<div style={{display:'inline-block', width:'35px', textAlign:'left', verticalAlign:'middle',}}>
								<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
							</div>
							<div className="m-p-0" style={{display:'inline-block', width:'100px', verticalAlign:'middle', textAlign:'left',}}>
								<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HOLA</div> 
								<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>
									{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
								</div>
							</div>
						</Link> 
					</div>
					}
				</div>
			</div>

			{loader?
				<Loader/>
			:
			<div className='container-large row'>
			<div>
				<div className='col s12 m2 l2 paqueteria'>
					<div onClick={()=>setServicioEnvio({proveedor:'Ecommerce', servicio: 'Recolección', precio:0, descripcion:'Recolección en sucursal'})} className='card-panel center' style={'Recolección'===servicioEnvio.servicio?{height:200, cursor:'pointer',boxShadow: '2px 2px 12px 3px rgba(255, 228, 219, 0.97)', border: '2px solid #FBB19A'}:{cursor:'pointer', height:200}}>
						<img className="logo-img" alt="" style={{verticalAlign: 'middle', objectFit: 'fill', width:'100%'}} />
						<p style={{width:'100%'}}>
							Recojelo tú mismo<br />
							$0.00 MXN
						</p>
					</div>
				</div>
				{packagingServices.map((s,i)=>
					<div className='col s12 m2 l2 paqueteria' key={i}>
						<div onClick={()=>setServicioEnvio({proveedor:'Fedex', servicio: s.ServiceDescription.ServiceType, precio:s.RatedShipmentDetails.ShipmentRateDetail.TotalNetCharge.Amount,descripcion:s.ServiceDescription.Names[1].Value, entrega:s.DeliveryTimestamp})} className='card-panel center' style={s.ServiceDescription.ServiceType===servicioEnvio.servicio?{height:200,cursor:'pointer',boxShadow: '2px 2px 12px 3px rgba(255, 228, 219, 0.97)', border: '2px solid #FBB19A'}:{cursor:'pointer', height:200}}>
							<img src='/assets/fedex.png' alt="envio"/ >
							<p style={{width:'100%'}}>
								{s.ServiceDescription.Names[1].Value}<br />
								{/*{s.ServiceDescription.ServiceType}<br />*/}
								${s.RatedShipmentDetails.ShipmentRateDetail.TotalNetCharge.Amount} {s.RatedShipmentDetails.ShipmentRateDetail.TotalNetCharge.Currency}
							</p>
						</div>
					</div>
				)}
			</div>

			{servicioEnvio.servicio&&
				<Link to='/micarrito/pago' className='btn black waves-effect waves-light right'>Continuar</Link>
			}


			</div>
			}
			
		</div>
		</>
	)
}

export default Envio
