import React, {useEffect, useState, useContext} from 'react';
import './pedidos.css'
import Nav from '../nav';
import M from 'materialize-css'
import axios from '../../axios';
import { Link} from 'react-router-dom';
import { DataContext } from '../../context/DataContext'
import Loader from '../Loader';

const Pedidos = () => {


	const { carrito, setCarrito } = useContext(DataContext)
	const [pedidos, setPedidos] = useState([])
	const [loader,setLoader] = useState(true)

	useEffect(() =>{
		setLoader(true)
		axios.get("/api/mispedidos").then(r => {
			setPedidos(r.data);
			setLoader(false)
			M.Tabs.init(document.querySelectorAll('.tabs'),{})
		}).catch(e=>console.log(e));
	},[])


	const _recomprar = (cantidad, codigo) => {
		axios.get(`/api/producto/${codigo}`)
		.then(r=>{
			if(r.data.stock >= cantidad){
				const obj = carrito.find((c)=>c.codigo===codigo)
				const newCantidad =  typeof(obj) === 'undefined' ? cantidad:obj.cantidad+cantidad
				setCarrito([...carrito.filter(c=>!c.codigo.includes(codigo)), {...r.data, cantidad:newCantidad}])
				M.toast({html: 'Carrito Actualizado!'})
			}
			else{
				alert("No contamos con ese número de productos en stock")
			}
		})
		.catch(r=>alert(r))
	}

		return (
			<div>
				<Nav />
				<div className='row m-p-0'>
					<div className='col s12 m-p-0 barra'>
						<div>Mis compras: </div>
					</div> 
				</div>
				{loader ? <Loader />:
				
				<div className="cont-result bg-container">
					<div className="container-medium margin-top-50">
						<div className='row'>
							<div className='col s12 m-p-0'>
								<div className='col s12 m-p-0'>
									<ul id="tabs-swipe-demo" className="tabs ul-tabs">
										<li className="tab col s2"><a className="active" href="#test-swipe-1">Pedidos en curso</a></li>
										<li className="tab col s2"><a href="#test-swipe-2">Pedidos</a></li>
									</ul>
								
									<div id="test-swipe-1" className="col s12 padding-tab">
									{pedidos.filter(p=>p.status!=='Entregado').length===0&&
									<h4>No tiene pedidos en curso</h4>
										
									}
									{pedidos.filter(p=>p.status!=='Entregado').map((pedido,i) => (
										<div key={i} className="pad-10-0  container-tabs">
											<div className="tabs-info">
												<div className="bloque2">
													<div className="row margin-10" >
														<div className='col s12 m10 m-p-0 pad-0-14 pedido-card-fecha'>
															<div className='negro' style={{fontSize:24}}>Pedido-{String(pedido.id).padStart(4, 0)}</div>
															{pedido.envio.servicio!=='Recolección'&&
															<div className="negro tittle-mobile-center">Llegando el {pedido.envio.entrega}
																<span className='infocolor-text font-12 font-b-100'>
																	&nbsp;&nbsp;Consulta en 24 hrs. El avance del pedido
																</span>
															</div>
															}
															{pedido.envio.servicio==='Recolección'&&
															<div className="negro tittle-mobile-center">Entrega en sucursal</div>
															}
														</div>
													</div>  
													<div className="row m-p-0 scroll-prod">
														{
														pedido.articulos.map((item,i) => (
														<div key={i} className="row m-p-0 card-pedido-lg">
															
															<div className='col hide-on-small-only m8 l9 m-p-0'>  
																<div className="text pad-5-0">Cantidad: {item.cantidad}</div>
																<div style={{fontSize:'12px!important',fontWeight:'100!important', lineHeight:1,}}> Producto: </div>
																<div className="card-tittle">  {item.nombre}</div>
																<div style={{fontSize:'12px!important',fontWeight:'100!important', lineHeight:1,}}> Descripción: </div>
																<div className="m-p-0 card-description">
																	{item.descripcion}</div>
															</div>
															<div className='col s12 m4 l3 img-card-pedido-cont'>
																<img className="img-card-pedido center" src={item.urls.length > 0 ? item.urls[0] : 'https://bitsofco.de/content/images/2018/12/broken-1.png'} alt='' />
															</div>
														</div>
														))}
													</div>
													<div className="text m-0 pad-5-0 row">
							
														<div className='col s12 m-p-0 pad-5-0 font-12'>
															<div className="cont-icon cont-icon-activo"> <i className="tiny material-icons m-p-0">check</i> </div>
															Pedido el {pedido.fecha}
														</div>
														{/*<div className='col s6 m-p-0 pad-10-0 font-12'>
															<div className="cont-icon"> <i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Llegando el {pedido.envio.entrega}
														</div>*/}
														
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Pagado','Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Pagado
														</div>

														{pedido.envio.servicio!=='Recolección'&&
														<>
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Enviado
														</div>
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															En camino
															<Link to={`/pedido/${pedido.id}`} style={{display:'block', marginLeft:'30px'}}>ver más</Link> 
															
														</div>
														</>
														}
														<div className='col s12 m3 m-p-0 pad-0-14 font-12 mobile-status'>
															<div className={['Entregado'].includes(pedido.status) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Entregado
														</div>
													</div>
												</div>
											</div>
										</div>
										))}
										







									</div>


									<div id="test-swipe-2" className="col s12 padding-tab">
									{pedidos.length===0&&
									<h4>No tiene pedidos</h4>
									}
									{pedidos.map((pedido,i) => (
										<div key={i} className="pad-10-0  container-tabs">
											<div className="tabs-info">
												<div className="row m-p-0 bloque1">
													<div className='col s12 m6 l3 m-p-0'>
														<div className='negro' style={{fontSize:24}}>Pedido-{String(pedido.id).padStart(4, 0)}</div>
														<div className="text">
															HECHO:
															<div className="textos">el {pedido.fecha} <span className="status">({pedido.status})</span></div>
														</div>
													</div>
													<div className='col m3 l2 m-p-0 text hide-on-small-only'>
														TOTAL:
														<div className="textos">${pedido.total} MXN</div>
													</div>
													<div className='col s12 m3 l2 m-p-0 text'>
														ENVIADO A:
														<div className="destinatario">{pedido.envio.nombre}</div>
													</div>
													{pedido.envio.servicio!=='Recolección'&&
													<Link to={`/pedido/${pedido.id}`} className="col s2 btn-azul rigth hide-on-med-and-down" style={{float:'right'}}> Seguir Pedido</Link>
													}
												</div>
												<div className="bloque2 m-0 scroll-item">
													{pedido.articulos.map((item,i) => (
														<div key={i} className="row m-0 pad-b-20">
															<div className='col s5 m3 l2 m-p-0 img-prod'>
																<Link to={`/product/${item.codigo}`} tabIndex="-1" className="ligaProd">
																	<div className="img-prod-hover">VER DETALLE<br></br>DE PRODUCTO</div>
																	<img src={item.urls.length > 0 ? item.urls[0] : 'https://bitsofco.de/content/images/2018/12/broken-1.png'} alt='' />
																</Link>
															</div>
															<div className='col s7 m9 l10 m-p-0 border-top'>
																<div className="negro">{item.nombre}</div>
																<div className="m-p-0 scroll-descripcion">&nbsp; {item.descripcion}</div>
																<div className="text pad-5-0 hide-on-small-only">
																	Cantidad {item.cantidad}<br></br>
																	Precio ${item.precio}
																</div>
															</div>
															<div className="col s12 text m-p-0 show-on-small-only hide-on-med-and-up">
																<div className="col s6 m-p-0">
																	<div className="text margin-top-5">
																		Cantidad {item.cantidad} </div>
																</div>
																<div className='col s6 m-p-0 pad-0-14 font-12'>
																	<div className="cont-icon margin-top-5"> 
																		<i className="tiny material-icons m-p-0">check</i> 
																	</div> 
																	{pedido.status}
																</div>
																<div className='col s12 linfocolor-text font-b-500 font-16 m-p-0 right-align'>
																	<div className="margin-10 infocolor-text orange-brick">Total: {Math.floor(item.cantidad * item.precio).toFixed(2)} <span>MXN</span></div>
																</div>
															</div>	
															<button className='col s12 padding-10 btn-negro show-on-medium-and-down hide-on-med-and-up margin-top-10'
															onClick={()=>_recomprar(item.cantidad,item.codigo)}
															style={{lineHeight:1,}}> Comprar nuevamente </button>
															
															<button className='col s2 btn-negro rigth hide-on-med-only hide-on-med-and-down'
															onClick={()=>_recomprar(item.cantidad,item.codigo)}
															style={{float:'right',lineHeight:1,}}> Comprar nuevamente </button>
															
														</div>
														))
													}
													<Link to={`/pedido/${pedido.id}`} className="col btn-azul btn-azul-mobile s12 show-on-medium-and-down hide-on-large-only" style={{marginTop:'10px'}}> Seguir Pedido</Link>

												</div>

												<div className="text row hide-on-small-only" style={{background:'#ffffff',margin:0, padding:'20px 0!important', paddingBottom:'20px!important', height:40}}>
													<div className='col m3'></div>
													<div className='col m3 linfocolor-text font-b-300 font-14 m-p-0 orange-brick' 
													style={{padding:0,}}>
														Subtotal ${pedido.subtotal} 
													</div>
													<div className='col m3 linfocolor-text font-b-300 font-14 m-p-0 orange-brick' 
													style={{padding:0,}}>
														Envio ${pedido.envio.precio}
													</div>
													<div className='col m3 m-p-0 pad-0-14 font-12'>
														<div className="cont-icon"> 
															<i className="tiny material-icons m-p-0">check</i> 
														</div> 
														<span className="linfocolor-text font-b-700 font-14 m-p-0"> {pedido.status} </span>
													</div>
												</div>	
											</div>
										</div>
									))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		);
};

export default Pedidos;
