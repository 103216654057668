import React ,{useEffect, useState,useContext} from 'react';
import axios from 'axios';
import { DataContext } from '../../context/DataContext';
import M from 'materialize-css'
import ModalAdress from '../ModalAdress/ModalAdress';

const Direcciones = () => {
    const [misDirecciones, setMisDirecciones] = useState([])
    const [dirSelected, setDirSelected] = useState(false)
    const { setDireccion } = useContext(DataContext)

    useEffect(() => {
		axios.get('/api/misdirecciones')
		.then(response=>{
            setMisDirecciones(response.data)
		}).catch(e=>console.log(e))
	},[])
    
    const _newDireccion = (obj) => {
        axios.post('/api/midireccion',obj)
        .then(res => {
            setMisDirecciones([...misDirecciones, obj])
            setDireccion(obj)
            M.toast({html: 'Dirección Agregada!'})
        }
        ).catch(e=>alert(e))
	};

    /**
     * @param {object} obj - direccion objet to update
     */
    const _editDireccion = (obj)=>{
        axios.put(`api/midireccion/${obj.id}`,obj)
        .then(res=>{
            setMisDirecciones(misDirecciones.map((item) => 
                (item.id === obj.id) ? obj:item
            ))
            M.toast({html: 'Dirección Actualizada!'}) 

        }).catch(e=>alert(e))
        setDirSelected(false)
    }
    /**
	  * @param {Object} obj - direccion object to delete
	  */
	const _deleteMiDirection = (obj) => {
		if(obj.id)
			axios.delete(`/api/midireccion/${obj.id}`)
			.then(r=>{
                setMisDirecciones(misDirecciones.filter(dir=>dir!==obj))
				M.toast({html: 'Dirección eliminada!'})
			})
			.catch(r=>alert(r))
	}
    
    return (
        <div className="row">
            <ModalAdress  
                _newDireccion={_newDireccion} 
                _editDireccion={_editDireccion} 
                direccion={dirSelected}
                setDirSelected = {setDirSelected}
                
                />
        { 
            misDirecciones.map((dir,i)=>
                <div className='col s12' key={dir.id}>
                    <div className="card" style={{cursor:'pointer'}}>
                        <div className="card-content">
                            <i className="material-icons grey-text right" onClick={()=>_deleteMiDirection(dir)}>delete</i>
                            <div>
                                <h6 className='bold'>Enviar a:  {dir.nombre}</h6>
                                <p>
                                    {dir.calle} #{dir.numExt} {dir.numInt?`Interior: ${dir.numInt}`:''}, {dir.colonia}, C.P. {dir.cp} {dir.ciudad}, {dir.estado}, {dir.pais}.
                                </p>
                                <h6 className='bold'>Número de Teléfono:</h6>
                                    {dir.telefono}
                            </div>
                            <div> 
                                <button data-target="modal" onClick={()=>setDirSelected(dir)} className="waves-effect waves-light modal-trigger btn-modal btn primarycolor">Editar</button> 
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
            
        </div>
    );
};

export default Direcciones;
