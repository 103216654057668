import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DataContext } from '../../context/DataContext'
import M from 'materialize-css';
import { useHistory } from 'react-router-dom'

const MiPerfil = () => {	

	const [data, setData] = useState({name:'',telefono:'',password:'',password_confirmation:''})
	const { user, setUser } = useContext(DataContext);
	const history = useHistory()

	useEffect(()=>{
		setData({name:user.name,telefono:user.telefono})
	},[user])

	const edit = (event) =>{
		event.preventDefault()
		if(data.password === data.password_confirmation){
			axios.put('/api/updateuser',data)
			.then(r => {
					setUser(r.data.user)
					M.toast({html:"Tus datos se actualizaron correctamente."})
					history.push('/miperfil')
			}).catch(err=>console.log(err))  
		}
		else{
			alert("Verifique sus datos");
		}
		   
	}

	return (
		<div>
			<div className='row'>
				<div className='col cont-footer-form' style={{width:'80%'}}>
					<h6 className="padding-10 bold">Ingresa los nuevos datos</h6>
					<form onSubmit={edit} className='col s12 editarPerfil'>
						<p className='footerForm m-p-0'>Nombre</p>
						<div className='col s12  m-p-0'>
							<input value={data.name} onChange={(e)=>setData({...data, name: e.target.value})} id='name' type='text' className='height33'/>
						</div>
						<p className='col s12 footerForm m-p-0 padding-top-50'>Telefono</p>
						<div className='col s12  m-p-0'>
							<input onChange={(e)=>setData({...data, telefono: e.target.value})} value={data.telefono} id='telefono' type='text' className='height33 footerForm-input validate'/>
						</div>
						<p className='col s12 footerForm m-p-0 padding-top-50'>Password</p>
						<div className='col s12  m-p-0'>
							<input value={data.password} onChange={(e)=>setData({...data, password:e.target.value})} id='password' type='password' placeholder='**********'  className='height33 footerForm-input validate'/>
						</div>
						<p className='col s12 footerForm m-p-0 padding-top-50'>Confirmar Password</p>
						<div className='col s12  m-p-0'>
							<input value={data.password_confirmation} onChange={(e)=>setData({...data, password_confirmation:e.target.value})} id='password_confirmation' type='password' placeholder='**********' className='height33 footerForm-input validate'/>
						</div>
						<div className='input-field col s12 p-0'>
							{data.password !== data.password_confirmation &&
							<span className='helper-text' data-error='error' style={{color:'red'}}>Los passwords no coinciden</span>
							}
							</div>
						<button className='btn waves-effect waves-light primarycolor right' type='submit' name='action'> 
							Guardar
							<i className='material-icons right'>send</i>
						</button>
					</form>
				</div>
			</div>
		</div>
	);
	
};

export default MiPerfil;
