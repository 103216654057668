import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom'
import './producto.css'
import axios from '../../axios'
import M from 'materialize-css'
import { DataContext } from '../../context/DataContext'
import Nav from '../nav'
import Vistos from '../Vistos/Vistos';
import Loader from '../Loader';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { RWebShare } from "react-web-share";

const Producto = () => {

	const { codigo, categoria_id } = useParams();
	const [producto, setProducto] = useState({imagenes:[]})
	const [imgSelect, setImgSelect] = useState({})
	const {carrito, setCarrito, user, vistos, setVistos} = useContext(DataContext)
	const [cantidad, setCantidad] = useState(1)
	const history = useHistory();
	const [loader, setLoader] = useState(true);
	const minimo = 1;

	useEffect(() => {
		setLoader(true)
		if(user.name !== undefined){
		/* Guarda los vistos */
		axios.post('/api/visto', {codigo})
		.then(r=>console.log())
		.catch(r=>alert(r))
		}
		axios.get(`/api/producto/${codigo}`)
		.then(r=>{
			var producto = r.data
			producto = ({...producto, minimo_compra: producto.stock<producto.minimo_compra?producto.stock:producto.minimo_compra})
			console.log(r.data)



			setProducto(producto)
			setCantidad(producto.minimo_compra)
			setImgSelect(producto.imagenes.length?r.data.imagenes[0]:{});
			setLoader(false)
			M.FormSelect.init(document.querySelectorAll('select'), {});
			M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});

			//style for select cant.:
			const selec = document.querySelector('.select-wrapper')
			selec.style.borderRadius='10px';
			selec.style.paddingLeft='10px';
			selec.style.boxShadow='0 4px 8px 0 rgba(0,0,0,0.2)';

			const selecI = document.querySelectorAll('.select-wrapper ul li span')
			for (var i = 0, len = selecI.length; i < len; i++)
				selecI[i].style.color='black'

			/**Para vistos con cache */
			if(vistos.find(v=>v.codigo===codigo) === undefined){
				setVistos([producto,...vistos.slice(0,24)])
			}
		})
		.catch(r=>console.log(r))
	},[codigo])

	/**
	  * @const _agregarAlCarrito - agrega un producto al carrito
	  * @return {void}
	  */
	const _agregarAlCarrito = () => {
		const obj = carrito.find((c)=>c.codigo===producto.codigo)
		const newCantidad =  typeof(obj) === 'undefined' ? cantidad:obj.cantidad+cantidad

		if(newCantidad > producto.stock) return alert("Lo sentimos no puedes agregar mas productos de este tipo, haz superado el stock")
		if(producto.stock === 0) return alert("Lo sentimos, por el momento no hay stock disponible")
		setCarrito([...carrito.filter(c=>!c.codigo.includes(producto.codigo)), {...producto, cantidad:newCantidad}])
		M.toast({html: 'Carrito Actualizado!'})
	}

	/**
	  * @const _comprar -
	  * @return {void}
	  */
	const _comprar = () => {
		setCarrito([{...producto, cantidad, selected:true}])
		history.push('/micarrito/direccion')
	}

	const _tallaSelected = (productoSelect) => {
		var producto = productoSelect
		producto = ({...producto, minimo_compra: producto.stock<producto.minimo_compra?producto.stock:producto.minimo_compra})
		setProducto(producto)
		setCantidad(producto.minimo_compra)
		setImgSelect(producto.imagenes.length?productoSelect.imagenes[0]:{});
		setLoader(false)
		M.FormSelect.init(document.querySelectorAll('select'), {});
		M.Materialbox.init(document.querySelectorAll('.materialboxed'), {});
		/**Para vistos con cache */
		if(vistos.find(v=>v.codigo===codigo) === undefined){
			setVistos([producto,...vistos.slice(0,24)])
		}
	}

	return (
		<>
		<Nav />
		{loader?
			<Loader/>
			:
			<>
		<div className='row hide-on-640' style={{marginBottom:'0px',}}>
			<div className='col s12 m-p-0 brad' style={{width:'100vw'}}>
				<div className="row m-p-0" style={{alignItems:'center'}}>
					<div className='migaja1'>
						<Link className="" to={`/deps/${producto.departamento_id}`}> {producto.departamento} &nbsp; > &nbsp; </Link></div>
					<div className='migaja2' style={{cursor:'default'}}>
						{
							categoria_id?
								producto.categorias.map((c,i)=>{
									if(c.id==categoria_id)
									return <Link key={i} className="" to={`/cat/${categoria_id}`} style={{cursor:'pointer'}}> {c.categoria} &nbsp; >  &nbsp; </Link>
								})
							:
							<>
							{
								producto.categorias.map((c,i)=>
								<Link key={i} className="" to={`/cat/${c.id}`} style={{cursor:'pointer'}}> {c.categoria} &nbsp; >  &nbsp; </Link>
								)
							}
							</>
						}
						</div>
					<div className="migaja3">{producto.nombre} </div>
				</div>
			</div>
		</div>
		<div>
			<div className='row container-detalle'>
				<div className='row m-0 contain-detail'>

					<div className='col s12 m6 row m-p-0 hide-on-640'>
						<div className='col s12 m1 m-p-0 es-pc'>
							<div className='col m-p-0 es-pc'>
							{producto.imagenes.map((img, i)=>
								<div key={i} onClick={()=>setImgSelect(img)} className="min-img-lateral">
									<img alt='' style={{objectFit:"scale-down"}} src={img.url}/>
								</div>
							)}
							</div>

						</div>
						<div className='col s12 m10'>
							<div className='m-p-0 center-y-x cont-img-prod' style={{}}>
								<img className='materialboxed' style={{objectFit:"scale-down", height:"100%", width:"100%"}}
								data-caption={producto.nombre} alt='' 
								src={imgSelect.url?imgSelect.url:'/assets/not-available.png'}/>
							</div>
						</div>
					</div>
					<div className='col s12 m6 m-p-0'>
						<div className='col' style={{width:'100%',}}>
							<h4 className='nombre-prod'>{producto.nombre}</h4>
							<i className='material-icons orange-text'>star</i>
							<i className='material-icons orange-text'>star</i>
							<i className='material-icons orange-text'>star</i>
							<i className='material-icons orange-text'>star</i>
							<i className='material-icons orange-text'>star</i>

							<p> <b>Stock:</b> {producto.stock} </p>
							{producto.stock===0&&<div style={{color:'red'}}>Producto no disponible</div>}
							{/*<div>
								<div className='col s9 prod-cont-texts'>
									<div className='col s12 infocolor-text prod-price'
									style={{fontSize:'18px', fontWeight:'700', padding:'20px 0', textAlign:'center!important',}}>${producto.precio} <span>MXN</span></div>	
								</div>
							</div>*/}
						</div>
					<div className='col s12 m6 row m-p-0 show-on-640' style={{display:'none'}}>
						<div className='col s12 m10 product-margin-responsive'>
							<div style={{position:'absolute',right:10, zIndex:2}}>
								<RWebShare data={{ text: "Check this out!", url: window.location.href, title: "Compartir"}} onClick={() => console.log("shared successfully!")} >
									<div style={{background:'white', width:50,height:50, borderRadius:50, display:'flex',alignItems:'center', justifyContent:'center', border:'1px solid grey'}}>
										<i className="material-icons">share</i>
									</div>
								</RWebShare>
							</div>
							<div className='m-p-0 center-y-x'>
								<Swiper modules={[Pagination]} loop={false} slidesPerView={1} spaceBetween={30} pagination={{ clickable: true }}>
									{producto.imagenes.map((p,i)=>
									<SwiperSlide id="carusel-elementos" key={i}>
										<img style={{objectFit:"scale-down", height:"100%", width:"100%"}} data-caption={p.nombre} alt='' src={p.url}/>
									</SwiperSlide>
									)}
								</Swiper>
							</div>
							<div style={{position:'relative'}}>
								<h3>${producto.precio}<label style={{position:'absolute', top:-10, color:'black', fontSize:18}}>00</label></h3>
							</div>
						</div>
					</div>




						{producto.tallas.length>0&&
						<div className='col' style={{width:'100%',}}>
							<div className='bold'>Tallas disponibles:</div>
							{producto.tallas.map((p,i)=>
								<Link key={i} to={`/product/${p.codigo}`} className={`btn waves-effect waves-light ${p.codigo===codigo?'primarycolor':'black'}`} style={{margin:1}}>{p.talla}</Link>
							)}
						</div>
						}

						<div className='col m-p-0 hide-on-640' style={{width:'100%'}}>
						<div className="row m-p-0" style={{background:'rgb(245, 245, 245)', borderRadius:'12px'}}>
							<div className="row" style={{margin:0, padding:'10px 15px 4px 15px'}}>
								<div className='col m3' style={{padding:'4px'}}> </div>
								<div className='col m6' style={{padding:'4px'}}>
									<div className="row m-p-0">
										<div className='col m3' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Minimo de Piezas</div>
										<div className='col m4' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Cantidad a comprar</div>
										<div className='col m5' style={{padding:'10px 5px 5px 5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>Precio</div>
									</div>
								</div>
								<div className='col m3' style={{padding:4}}>
									<a href='#!' onClick={_agregarAlCarrito} style={{width:'90%!important', margin:'3px'}} className={'btn waves-effect waves-light btn-add black'+(producto.stock>0?'':' disabled')}>Agregar al Carrito</a>
								</div>
						
							</div>
							<div className="row" style={{borderTop:'solid 1px #EBEBEB', margin:0, padding:'10px 15px'}}>
								<div className='col m3' style={{padding:'5px', textAlign:'center', fontWeight:'500', lineHeight:'1'}}>
									Cantidad
									<p className="m-p-0" style={{color:'#999999',fontSize:'12px', fontWeight:'500'}}>(Paquetería normal)</p>
								</div>
								<div className='col m6' style={{padding:'4px'}}>
									<div className="row m-p-0">
										<div className='col m3' style={{textAlign:'center', padding:'8px 5px 5px 5px'}}>{producto.minimo_compra}</div>
										<div className='col m4' style={{textAlign:'center', padding:'5px'}}>
											
											<div className="row m-p-0" style={{background:'#ffffff', borderRadius:'6px', height:'30px',}}>
												<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px',}}>
													<div onClick={(e)=>setCantidad((cantidad > producto.minimo_compra)?cantidad-1 : cantidad)}
													style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> - </div>
												</div>
												<div className='col m4' style={{textAlign:'center', padding:'5px 5px 5px 5px',}}>
													{cantidad}
												</div>
												<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px',}}>
													<div onClick={(e)=>setCantidad((cantidad < producto.stock)?cantidad+1 : cantidad)}
													style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> + </div>
												</div>
											</div>
										</div>
										<div className='col m5 m-p-0' style={{}}>
											<div className='nfocolor-text prod-price'
											style={{fontSize:'14px', fontWeight:'700', marginTop:'10px',width:'100%', textAlign:'center'}}>${producto.precio * cantidad} <span>MXN</span></div>	
											
										</div>
									</div>
								</div>
								<div className='col m3' style={{padding:'4px'}}>
									<a href='#!' onClick={_comprar} style={{width:'90%', margin:'3px'}} className={'btn black waves-effect waves-light btn-comprar '+(producto.stock>0?'':'disabled')} >Comprar</a>
								</div>
							</div>
						</div>
						</div>


						<div className='col s12 show-on-640' style={{display:'none'}}>
						{producto.stock>0&&
							<div style={{color:'green'}}>Disponible</div>
						}
							<div style={{width:100}}>
							<select onChange={(e)=>setCantidad(e.target.value)} value={cantidad}>
								{Array(producto.stock>producto.minimo_compra?producto.stock-producto.minimo_compra+1:producto.stock).fill(null).map((e,i)=>
								<option valu={producto.stock>producto.minimo_compra?i+producto.minimo_compra:i+1}>Cant.: {producto.stock>producto.minimo_compra?i+producto.minimo_compra:i+1}</option>
								)}
							</select>
							</div>
							<a href='#!' onClick={_comprar} style={{margin:'3px', background:'#FFA617', display:'block', borderRadius:15}} className={'btn waves-effect waves-light black-text'+(producto.stock>0?'':' disabled')} >Comprar ya</a>
							<a href='#!' onClick={_agregarAlCarrito} style={{margin:'3px', background:'#FED90B',display:'block', borderRadius:15}} className={'btn waves-effect waves-light btn-addi black-text'+(producto.stock>0?'':' disabled')}>Agregar al Carrito</a>
							<a href='#!' style={{display:'flex', alignItems:'center', margin:'20px 0px 20px 0px'}}><i className="material-icons grey-text">lock</i>Transacción segura</a>
							<div style={{marginTop:10}}>
							<div className='row' style={{marginBottom:0}}>
								<div className='col s6 grey-text'>Envío desde</div>
								<div className='col s6'>México</div>
							</div>
							<div className='row'>
								<div className='col s6 grey-text'>Vendido por</div>
								<div className='col s6 store-name'></div>
							</div>
							</div>
						</div>



						<div className='col'>
							<p style={{lineHeight:'1.2', textAlign:'justify'}}>
								<b>Acerca de este artículo: </b><br></br>
								{producto.descripcion?producto.descripcion:'Sin descripción'}
							</p>
						</div>
					</div>
				</div>

				<div className='col 12' style={{width:'100%'}}>
					<div style={{minHeight:531, borderRadius:15, background:'#F5F5F5', padding:30, marginTop:'10px'}}>
						<div>
							<div style={{borderBottom:'2px solid black', display:'inline-block', fontSize:18, fontWeight:'bold'}}>
								Detalles de producto
							</div>
						</div>
						<div style={{fontSize:28, fontWeight:'bold', marginTop:34}}>
							Descripción General
						</div>
						<div style={{fontSize:'16px', marginTop:13}}>
							<div style={{fontSize:'14px'}} dangerouslySetInnerHTML={{__html: producto.descripcion_general}} />
						</div>
					</div>
				</div>
			</div>
			{/* VIstos recientemente */}
			<div className='row' style={{padding:'0!importat', margingBottom:'40px'}}>
				
				<div className="col s12 m12 container-prod">
					<div className="container-blanco"> 
						<div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
						<div className='row center-y-x' style={{padding:'6px'}}> 
							{/* VIstos recientemente */}
							<Vistos />
						</div> 
					</div>
				</div>

				<div style={{padding:'30px'}}></div>
			</div>
		
		</div>
		</>
		}
		</>
		
	)
}

export default Producto
