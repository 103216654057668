import React, { useContext, useState, useEffect} from 'react';
import { Link, useHistory, useLocation} from 'react-router-dom'
import M from 'materialize-css'
import './nav.css'
import { DataContext } from '../../context/DataContext'
import axios from 'axios';

const Nav = () => {

	const { user, carrito, setUser,config} = useContext(DataContext)
	const [key, setKey] = useState('')
	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: "/" } };
	
	useEffect(()=>{
		M.Sidenav.init(document.querySelector('#slide-out'), {});
		
	},[]);
	
	const _search = (e) =>{
		e.preventDefault()
		history.push(`/search/${key}`)
	}

	const _push = (e) => {
		e.preventDefault()
		setTimeout(() => {
			document.getElementById("searchI").focus()
		}, 0);
		history.push(`/search`)
	}

	const _pushMovil = (e) => {
		e.preventDefault()
		e.stopPropagation()
		setKey(e.target.value)
		history.push(`/search`)
	}

	const _pull = (e) => {
		e.preventDefault()
		history.push(`/`)
	}
	
	const logout = () =>{
		axios.delete('/api/logout')
		.then(res => {
			setUser({})
			history.replace(from)
		}).catch(e=>alert(e))
	}


	return(
		<>
		<div className='navbar-fixed' style={{height:90}}>
			<nav className='navbackground'>
				<div className='nav-wrapper container-menu es-nav' style={{width:'100%!important'}}>
					<ul id='nav-mobile' className='left hide-on-med-and-down cont-ul'>
						<li className="logo">
							<Link to='/' className=''>{config.logo?<img className='logo-img'  alt='' style={{verticalAlign:'middle', objectFit:'fill'}} /> : <p> &nbsp; </p>}</Link>
						</li>
						<li className="" style={{margin:'0 10px 0 10px', width:'230px'}}>
							<Link to='/deps' className='link-a center-x-y navtext' style={{textAlign:'center'}}>
								<div className={location.pathname==="/deps"||location.pathname.includes('product')||location.pathname.includes('cat')||location.pathname.includes('search')?'item-name-active':''}><div className='nombre-seccion-principal'></div></div>
							</Link>
						</li>
						<li className="buscar">
							<form onSubmit={_search}>
								<label>
									<input className="border-input" id="searchI" type='search' value={key} onChange={(e)=>setKey(e.target.value)} onFocus={_push} onBlur={(e)=> { if(e.relatedTarget === null) _pull(e) }} style={{border:'none'}} />
									<Link to={`/search/${key}`} className="buscar-a"><i className='material-icons primarycolor white-text'>search</i></Link>
								</label>
							</form>
						</li>
						<li className="buy">
							{user.name?
							<Link  className='link-a navtext' to='/pedidos'> 
								<div style={{display:'block'}}>
									<div className={location.pathname==="/pedidos"?'item-name-active':''}>MIS COMPRAS</div>
								</div>
							</Link>:
							<Link to='/login' className={location.pathname==="/login"?'item-name-active':'link-a navtext'}>MIS COMPRAS</Link>
							}
						</li>
						<li className="login">
							{user.name?	
							<Link className='link-a' to='/miperfil'>
								<i className='material-icons navtext'>account_circle</i>
									<div className={location.pathname==="/miperfil"?'item-name-active':''} style={{width:'100%', textAlign:'left'}}>
										<div className='navtext' style={{fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HOLA</div> 
										<div className='navtext' style={{fontSize:'14px', width:'100%', lineHeight:'1'}}>
											{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
										</div>
									</div>
							</Link>:
							<div>
								<Link  className='link-a navtext' to='/registro'>
									<div style={{display:'block'}}>
										<div className={location.pathname==="/registro"?'item-name-active':''}>IDENTIFICATE </div>
									</div>
								</Link>
							</div>
							}
						</li>
						<li className="buy">
							<Link to='/micarrito' className={`link-a navtext ${location.pathname==="/micarrito"?'activar-carritoi':''}`} >
								{carrito.length > 0 ?
								<div style={{position:'relative'}}>
									<i className={`material-icons left car ${location.pathname==="/micarrito"?'item-name-active-color':''}`} style={{marginTop: '0px'}}>shopping_cart</i>
									<span className="badge badge-car item-name-active-color articulos center-x-y number-badge">
										{(carrito.reduce((a,b) => a+parseInt(b.cantidad),0) > 9) ? "9+" : carrito.reduce((a,b) => a+parseInt(b.cantidad),0 )}
									</span>
								</div>:
								<i className={`material-icons left car ${location.pathname==="/micarrito"?'item-name-active-color':''}`} style={{marginTop: '0px'}}>shopping_cart</i>
								}
								<span className={location.pathname==="/micarrito"?'activar-carritoi item-name-active':''} style={{padding:10}}> CARRITO</span>
							</Link>
						</li>
					</ul>
				</div>

				<div className='nav-wrapper container-menu es-movil'>
					<ul id='nav-mobile' className='left hide-on-med-and-down cont-ul'>
						<li className="log">
							<a href="#!" data-target="slide-out" className="sidenav-trigger" style={{display:'flex', alignItems:'center'}}>
								<i className="material-icons navtext">menu</i>
								<img className='logo-img' style={{verticalAlign:'middle'}} alt=''/>
							</a>
						</li>

						<li className="buscar">
							<form onSubmit={_push} style={{margin:0,padding:0,width:'100%'}}>
								<label className="label-search" style={{margin:0,padding:0,width:'100%',}}>
									<input className="border-input" type='search' value={key} onChange={(e)=>setKey(e.target.value)} style={{border:'none', height:'35px', margin:0,padding:0,width:'100%'}} />
									<Link to={`/search/${key}`} className="buscar-a"> <i className='material-icons primarycolor white-text'>search</i></Link>
								{/*<a href='#!' onClick={_search} className="buscar-a">
									<i className='material-icons border-input primarycolor white-text'>search</i>
								</a>*/}
								</label>
							</form>
						</li>
					
						<li className="buy">
							<Link to='/micarrito' className={`link-a ${location.pathname==="/micarrito"?'activar-carrito':''}`} >
								{carrito.length>0?
								<div style={{position:'relative'}}>
									<i className={`material-icons left car ${location.pathname==="/micarrito"?'activar-carrito':''}`} >shopping_cart</i>
									<span className="badge primarycolor white-text articulos center-y-x number-badge">
									{(carrito.reduce((a,b) => a+parseInt(b.cantidad),0) > 9) ? "9+" :
										carrito.reduce((a,b) => a+parseInt(b.cantidad),0 )
									}
										{/*{(carrito) ? carrito.length :0}*/}
									</span>
								</div>:
								<i className={`material-icons left car ${location.pathname==="/micarrito"?'activar-carrito':''}`} style={{marginTop: '12px',}}>shopping_cart</i>
								}
								<span className={location.pathname==="/micarrito"?'activar-carrito':''}></span>
							</Link>
						</li>
					</ul>
				</div>
			</nav>
		</div>

		<ul id="slide-out" className="sidenav">
			<li className="itemi center-y-x" style={{height:'150px', padding:'20px'}}>
				<div>
					<Link to='/' className='link-a black-text'> 
						<img className='logo-img' style={{verticalAlign:'middle', height:'80px', width:'80px'}} alt=''/>
					</Link>
				</div>
			</li>
			<li className="item">
				<div className="buscar">
					<form onSubmit={_pushMovil} id="nav-buscar">
						<label className="label-search">
							<input name="key2" id="searchinput2" className="border-input" type='search' value={key} onChange={(event)=> setKey(event.target.value)} />
							<Link to={`/search/${key}`} className="buscar-a btn-buscar-movil primarycolor">
								<i className='material-icons primarycolor white-text'>search</i>
							</Link>
						</label>
					</form>
				</div>
			</li>
			{(user.name)?	
			<li className="item">
				<Link className='link-a black-text' to='/miperfil'>
					<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
					<div style={{width:'100%', textAlign:'left',paddingTop:'10px'}}>
						<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%',marginBottom:'2px', lineHeight:'1'}}>HOLA</div>
						<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>{user.name}</div>
					</div>
				</Link> 
			</li>:
			<>
			<li className="item">
				<Link to='/registro' className='link-a black-text'>
					<i className="material-icons">person</i>
					REGISTRATE
				</Link>
			</li>
			<li className="item">
				<Link to='/login' className='link-a black-text'>
					<i className="material-icons">center_focus_strong</i>
					IDENTIFICATE
				</Link>
			</li>
			</>
			}
			<li className="item">
				<Link to='/deps' className='link-a black-text'><i className="material-icons">grid_on</i><span className='nombre-seccion-principal'></span></Link>
			</li>
			<li className="item">
				<Link to='/micarrito' className='link-a black-text'>
					<i className='material-icons left car'>shopping_cart</i> CARRITO
					<span className="badge primarycolor white-text"
					style={{borderRadius:'100%',minHeight:'20px',minWidth:'20px', fontSize:'10px'}}>{(carrito)?carrito.length:0}</span>
				</Link>
			</li>
			<li className="item">
				<Link to={(user.name) ? '/pedidos':'/login'} className='link-a black-text'> <i className="material-icons">attach_money</i> MIS COMPRAS </Link>
			</li>
			{user.name &&
			<li className="item">
				<div onClick={logout} className="col s12 semi-bold" style={{padding:'0 30px'}} >
					<img src="../assets/logout.png" style={{height:"19px",paddingRight:'30px'}} alt="exit" />
					CERRAR SESIÓN
				</div> 
			</li>
			}
		</ul>
		</>
	)
}

export default Nav
