import React, { useEffect, useContext, useState } from 'react';
import StatusBar from '../statusBar';
import axios from '../../../axios'
import { DataContext } from '../../../context/DataContext' 
import { useHistory, Link, useLocation } from 'react-router-dom';
import Nav from '../../nav'
import M from 'materialize-css'
import OxxoPay from './metodos/oxxoPay'
import PayPal from './metodos/payPal'
import SantanderPay from './metodos/santander'
import html2canvas from 'html2canvas'
import Loader from '../../Loader'


const Pago = () => {

	const { carrito, servicioEnvio, direccion, setDireccion, user, config } = useContext(DataContext)
	const [pedido, setPedido] = useState({pay:{link:''},envio:{descripcion:'', precio:0}, articulos:[]})
	const [totals, setTotals] = useState({subtotal:0, total:0})
	const [loader, setLoader] = useState(true)
	const [data, setData] = useState({cupon:''})
	const [descuentoCupon, setDescuentoCupon] = useState(0)
	const [metodoPago, setMetodoPago] = useState('')
	const [reloadPaypal, setReloadPaypal] = useState(false)

	const history = useHistory();
	const location = useLocation();


	useEffect(() => {
		const carritoAux = carrito.filter(c=>c.selected)
		const total = (carritoAux.reduce((a,b)=>a+(parseInt(b.cantidad)*parseFloat(b.precio)),0)+parseFloat(servicioEnvio.precio)).toFixed(2)
		setTotals({subtotal:carritoAux.reduce((a,b)=>a+(parseInt(b.cantidad)*parseFloat(b.precio)),0), total})
		
		if(carritoAux.length){
			axios.post('/api/pedido', { carrito:carritoAux, servicioEnvio, direccion, total })
			.then(r=>{
				setPedido(r.data)
				if(r.data.nuevaDireccion) setDireccion(r.data.nuevaDireccion)

				setTimeout(()=>{
					setLoader(false)
				},0)
			})
			.catch(r=>alert(r))
		}else{
			M.toast({html:"Seleccione articulos!"})
			const locate = { pathname: '/micarrito' };
			history.replace(locate)
		}


	 // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const _Entrega = () => {
		if(servicioEnvio.entrega){
		const fechaE = new Date(servicioEnvio.entrega)
		const fecha1=fechaE.toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })
		fechaE.setDate(fechaE.getDate() + 5);
		const fecha2=fechaE.toLocaleDateString('es-MX', { year: 'numeric', month: 'long', day: 'numeric' })

		return fecha1+' - '+fecha2
		}
		if(servicioEnvio.servicio==='SAME_DAY_CITY'){
			return servicioEnvio.descripcion
		}
		return 'Fecha Desconocida consulte su guía en fedex'
	}

	const _getCupon = () => {
		axios.post(`/api/aplicacupon`,{cupon:data.cupon,pedido_id:pedido.id})
		.then(r=>{
			setDescuentoCupon(r.data.descuento_aplicado)
			setTotals({...totals, total:r.data.total})
			setPedido({...pedido,pay:r.data.pay})
			M.toast({html: 'Cupón aplicado!', classes:'toast-container-editado'})
			setReloadPaypal(true)
		})
		//.catch(r=>alert(r.response.data.message))
		.catch(r=>alert('Cupón no valido'))
		setMetodoPago('')
	}

	const _metodosPagoShow = () => {
		switch(metodoPago){
			case 'oxxo':
				return(
					<OxxoPay setMetodoPago={setMetodoPago} pedido={pedido}/>
				)
				break
			case 'tarjeta':

				return(
					<SantanderPay setMetodoPago={setMetodoPago} pedido={pedido}/>
				)
				break
			default:
			return(
				<>
					Seleccionar un método de pago
					<div className='card-panel' style={{display:'flex', alignItems:'center', position:'relative', cursor:'pointer'}} onClick={()=>setMetodoPago('tarjeta')}>Tarjeta de credito/debito <img src='https://icon-library.com/images/visa-master-icon/visa-master-icon-29.jpg' height='50' style={{position:'absolute', right:10}}/></div>
					<div className='card-panel' style={{display:'flex', alignItems:'center', position:'relative', cursor:'pointer'}} onClick={()=>setMetodoPago('oxxo')}>Paga en efectivo en OXXO <img src='https://aymdesarrolloempresarial.com/wp-content/uploads/2016/11/Icono-Logo-Oxxo.png' height='50' style={{position:'absolute', right:10}}/></div>
					<div style={{margin:10}}> <PayPal pedido={pedido} totals={totals} reloadPaypal={reloadPaypal} setReloadPaypal={setReloadPaypal}/></div>
				</>
			)
		}
	}


return (
	<>
		{user.name===undefined&&
			<Nav />
		}
		<div className="cart-container-short">
			<div className="center-y-x" style={{backgroundColor:'#ffffff', paddingBottom:'40px'}}>
				<div className="" style={{ width:'50px', marginRight:'50px', display:'inline-block',}}>
					<Link to='/' className=''>
						<img className='logo-img' style={{verticalAlign:'middle', height:'40px', width:'40px'}} alt=''/>
					</Link>
				</div>
				<div className="center-y-x">
					<StatusBar status='pago'/>
				</div>
				<div className="center-y-x" style={{paddingLeft:'50px', display:'inline-block'}}>
					{user.name&&
					<div>
						<Link  className='link-a black-text' to='/miperfil'>
							<div style={{display:'inline-block', width:'35px', textAlign:'left', verticalAlign:'middle',}}>
								<i className='material-icons' style={{color:'#000000',}}>account_circle</i>
							</div>
							<div className="m-p-0" style={{display:'inline-block', width:'100px', verticalAlign:'middle', textAlign:'left',}}>
								<div style={{color:'#000000', fontSize:'10px', fontWeight:'500', width:'100%', lineHeight:'1'}}>HOLA</div> 
								<div style={{color:'#000000', fontSize:'14px', width:'100%', lineHeight:'1'}}>
									{(user.name.indexOf(' ') >= 0) ? user.name.substr(0,user.name.indexOf(' ')) : user.name }
								</div>
							</div>
						</Link> 
					</div>
					}
				</div>
			</div>
			
			{loader?
			<div className='progress'>
				<div className='indeterminate primarycolor'></div>
			</div>
			:
			<div className='row' style={{maxWidth:1400}}>
				<div className='col s12 m6 pago-100'>
					<div className='card-panel' style={{minHeight:'715px',fontSize:14, color:'#4D4D4D'}}>
						<h4 className='black-text'>Resumen Del Pedido-{pedido.id}</h4>
						<div className='row' style={{marginBottom:0}}>
							<div className='col m6'>Subtotal:</div>
							<div className='col m6'>${totals.subtotal}</div>
						</div>
						<div className='row'>
							<div className='col m6'>{servicioEnvio.descripcion}:</div>
							<div className='col m6'>${servicioEnvio.precio}</div>
						</div>
						{descuentoCupon!==0&&
						<div className='row'>
							<div className='col m6'>Cupón:</div>
							<div className='col m6'>-${descuentoCupon}</div>
							<div className='col m12' style={{fontSize:12, color:'red'}}>*descuento no aplica a gastos de envío</div>
						</div>
						}
						<div className='row bold' style={{marginBottom:10, color: '#DF3F0C', fontSize:16}}>
							<div className='col m6'>Total:</div>
							<div className='col m6'>${totals.total}</div>
						</div>
						<div className='row'>
							<div className='input-field col s6'>
								<input value={data.cupon} onChange={(e)=>setData({...data, cupon:e.target.value})} id='cupon' type='text' className='validate' />
								<label htmlFor='cupon'>Cupón</label>
							</div>
							<div className='input-field col s6'>
								<a href='#!' onClick={_getCupon} className='btn waves-effect waves-light'>Canjear</a>
							</div>
						</div>
						<div className='row' style={{marginBottom:0}}>
							Pago procesado de manera segura
						</div>
						<div className='row' style={{marginBottom:0}}>
							<img src='/assets/santander.png' alt="santander" />
						</div>
						<div className='row' style={{marginBottom:0}}>
							<div className='popover__wrapper'>
								<div className='popover__title'>¿Problemas con tu pago?</div>
								<div className='popover__content'>
									<p className='popover__message'>
										1.- Revisa que tengas fondos en tu tarjeta.<br />
										2.- Revisa que la información sea la correcta.<br />
										3.- Recuerda que es importante activar el 3dsecure con tu banco para poder realizar pagos en linea.<br />
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col s12 m6 pago-100'>
					<div style={{padding:20,border: '2px solid #FBB19A', boxShadow: '2px 2px 12px 3px rgba(255, 228,219, 0.97)'}}>
						{_metodosPagoShow()}
					</div>
				</div>
				<div className='col m12 pago-100'>
					<div className='card-panel' style={{color:'#4D4D4D'}}>

					<div className='row'>
					{pedido.envio.servicio!=='Recolección'&&
					<div className='col s12 m6 pago-100' style={{padding:'100px 30px 100px 30px'}}>
						
						<>
						<h4 className='black-text'>Dirección de envío</h4>
						<div className='bold'>Enviar a: {pedido.envio.nombre}</div>
						<p>{pedido.envio.calle} {pedido.envio.numExt} {pedido.envio.numInt}, {pedido.envio.colonia} <br />C.P {pedido.envio.cp} {pedido.envio.ciudad} {pedido.envio.estado}</p>
						<div className='bold'>Número de Teléfono:</div>
						<p>{pedido.envio.telefono}</p>
						<div className='bold black-text'>Proveedor de envío:</div>
						<p>{pedido.envio.descripcion}</p>
						</>
					</div>
					}
					<div className='col s12 m6 pago-100' style={{borderLeft:'1px solid #D1D1D1'}}>
						<div style={{height:600, overflowX:'hidden'}}>
							{pedido.envio.servicio!=='Recolección'&&
							<div className='bold'style={{color:'#DF3F0C'}}>
								Fecha de entrega estimada: {_Entrega()}
							</div>
							}
							<h4> Productos: </h4>
							{pedido.articulos.map((a,i)=>
							<div className='row' key={i}>
								<div className='col'>
									<img width='200' src={a.urls?a.urls.split(',')[0]:'https://bitsofco.de/content/images/2018/12/broken-1.png'} alt={a.nombre}/>
								</div>
								<div className='col'>
									<p>
										Nombre: {a.nombre}<br />
										Cantidad: {a.cantidad}
									</p>
									<div className='bold'> ${a.precio}</div>
								</div>
							</div>
							)}
						</div>
					</div>
					</div>

					</div>
				</div>
			</div>
			}
		</div>
	</>
)
}

export default Pago
