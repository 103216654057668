import React, { useEffect,useState } from 'react';
import Nav from '../../nav';
import axios from 'axios';
import Loader from '../../Loader';

const PoliticasCompras = () => {
    const [politica , setPolitica] = useState({})
    const [loader ,setLoader] = useState(true)

    useEffect(()=>{ 
        setLoader(true)
		axios.get('/api/politicacompra').then(r=>{
            setPolitica(r.data.politica_compra)
            setLoader(false)
		})
    },[])

    return (
        <div>
            <Nav />
             <div className='row m-p-0'>
                <div className='col s12 m-p-0 barra'>
                    <div>Politicas Compras:</div>
                </div>
            </div>
            {loader ? 
            <Loader />
            :
            <div className="cont-result">
				<div className='row center-y-x'>
					<div className='col s10 m10 l8 m-p-0'>    
					    <div dangerouslySetInnerHTML={{__html: politica}} />   
                    </div>
                </div>
            </div>
            }
        </div>
    );
};

export default PoliticasCompras;