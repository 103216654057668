import React, { useEffect, useState, useContext} from 'react';
import './resultSearch.css'
import Nav from '../nav';
import { useParams, Link } from "react-router-dom";
import ProductCard from '../ProductCard/ProductCard';
import axios from '../../axios'
import Vistos from '../Vistos/Vistos';
import { DataContext } from '../../context/DataContext'
import Loader from '../Loader';

const ResultSearch = () => {
 	const {buscados, setBuscados} = useContext(DataContext)
	const {key} = useParams()
	const [productos, setProductos] = useState([])
	const [loader, setLoader] = useState(true)
	
	useEffect(()=>{
		setLoader(true)
		axios.post('/api/producto/search', {search: key})
		.then(r=>{
			setProductos(r.data.filter( (item, i) => i === r.data.findIndex( elem => elem.id === item.id)))
			setLoader(false)
		})
		.catch(r=>alert(r))
		/**Para busqueda con cache */
			if(!(buscados.find(b=>b===key)))
				setBuscados([...buscados.slice(-24), key]);
			//setBuscados([...buscados.filter(b=>b!==key).slice(-24), key]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[key])

	return (
		<div>
			<Nav />
			{loader?
			<Loader />
			:
			<div className="bodyHeight">
		
				<div className='row' style={{marginBottom:'0px',}}>
					<div className='col s12 m-p-0 brad'>
						<div className="row m-p-0" style={{alignItems:'center',}}>
							<div className='migaja3'>Buscar &nbsp; : &nbsp; {key} </div>
						</div>
					</div>
				</div>
		
				<div className="cont-listado">
					<div className='row' style={{maxWidth:'1270px',}}>
					{productos.length > 0 ?
						productos.map((p,i)=>
						<div key={i} className='col m-p-0' style={{height:430}}>
							<Link to={`/product/${p.codigo}`} >
								<ProductCard producto={p} />	
							</Link>
						</div>
						) :
						<div className="container">
							<div className="row center">
								<div className="center">
									<i className="small material-icons">info</i>
									<h6>Lo sentimos no se han encontrado resultados, prueba con otra busqueda.</h6>	
								</div>
							</div>
						</div>
					}
					
					</div>
				</div>

					<div style={{padding:'30px'}}></div>
                    {/* VIstos recientemente */}
                    <div className="container-large" tabIndex="0">
                        <div className="container-blanco"> 
                            <div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
                            <div className='row center-y-x' style={{padding:'6px'}}> 
                                {/* VIstos recientemente */}
								<Vistos></Vistos>
                            </div> 
                        </div>
                    </div>
                    <div style={{padding:'30px'}}></div>
			</div>
			}
		</div>
	);
};

export default ResultSearch;
