import React, { useEffect, useState } from 'react'
import axios from '../../../../axios'
import { useHistory, useLocation } from 'react-router-dom';

const SantanderPay = ({pedido, setMetodoPago}) => {

	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: '/micarrito/finaliza' } };

	useEffect(()=>{

		const interval = setInterval(()=>{
			axios.get(`/api/statuspedido/${pedido.id}`)
			.then((r)=>{
				if(r.data.status==='Pagado'){
					clearInterval(interval)
					history.replace(from)
				}
			})
			.catch((r)=>alert(r))
		}, 5000)

		return () => clearInterval(interval);
	},[])


	return(
		<>
			<a href='#!' onClick={()=>setMetodoPago('')}>volver</a>
			<h4>Agregar Tarjeta</h4>
			<iframe title='pay' frameBorder='0' src={pedido.pay.link} scrolling='no' seamless='seamless' style={{width:'100%', minHeight:'600px'}}/>
		</>
	)
}

export default SantanderPay
