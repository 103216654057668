import { createBrowserHistory } from 'history';

window.axios = require('axios');
window.axios.defaults.baseURL = 'https://api.store.kangoru.mx';

const urlException = [
	'/api/user',
	'/api/misdirecciones'
]

window.axios.interceptors.response.use(
    function (response) { 
        return response; 
    }, 
    function (error) { 
        if (401 === error.response.status && !urlException.includes(error.response.config.url)) { 
            //window.location.replace('login');
           createBrowserHistory().push('/login');
           //window.location.reload();
        }
	if(error.response.status===404){
           createBrowserHistory().push('/404');
           window.location.reload();
	}

	return Promise.reject(error);
    });

window.axios.defaults.headers.common['Authorization'] = localStorage.getItem('access_token');
export default window.axios;
