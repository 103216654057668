import React, {useEffect, useState} from 'react';
import axios from '../../../axios';
import Nav from '../../nav';

const Aviso = () => {
    const [aviso, setAviso] = useState('');
    useEffect(()=>{
        axios.get(`/api/avisoprivacidad`)
		.then(r=>{
			setAviso(r.data)
		})
		.catch(r=>alert(r))
    },[])
    return (
        <div>
			<Nav />
            <div className='row'>
                <div className='col s12 m-p-0 brad'>
                    <div>Aviso de Privacidad</div>
                </div>
            </div>
            <div className="cont-result">
                
				<div className='row center-y-x'>
					<div className='col s10 l8 m-p-0'>
                        <div className="somos">Aviso de Privacidad </div>
                        <div dangerouslySetInnerHTML={{__html: aviso.aviso_privacidad}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Aviso;
