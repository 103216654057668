import React, {useContext} from 'react';
import './productCard.css'
import M from 'materialize-css'
import { DataContext } from '../../context/DataContext'

/**
  *
  * @param [Object] producto
  * @param [String] producto.codigo
  * @param [String] producto.nombre
  * @param [String] producto.descripcion
  * @param [Array] producto.imagenes
  * @param [Decimal] producto.precio
  * @param [Integer] producto.stock
  *
  */
const ProductCard = ({producto}) => {

	const { carrito, setCarrito } = useContext(DataContext);
	
	/**
	  * @const _agregarAlCarrito - agrega un producto al carrito
	  * @return {void}
	  */
	const _agregarAlCarrito = () => {
		setCarrito([...carrito.filter(c=>!c.codigo.includes(producto.codigo)), {...producto, cantidad:producto.stock<producto.minimo_compra?producto.stock:producto.minimo_compra}])
		M.toast({html: 'Carrito Actualizado!'})
	}

	return (
		<div className='prod-card'>
			<div className="prod-container">
				<center className="prod-position" style={{position:'relative', zIndex:'1'}}>
					<div className='bold center-y-x prod-cont-img'>
						<img className="" src={producto.imagenes.length?producto.imagenes[0].url:'/assets/not-available.png'} alt='' />
					</div>
				</center>
				<div className="prod-info">
					<div className="row m-p-0">
						<div className='prod-name'>{producto.nombre}</div>
						<p className="col s12 prod-descripcion">{producto.descripcion?producto.descripcion:'Descripción no disponible'}</p>
					</div>
					<div className="m-p-0" style={{borderRadius:' 0 0 12px 12px',}}>
						<div className="row m-p-0">
							<div className='col s9 prod-cont-texts'>
								<div className='col s12 infocolor-text prod-price'>${producto.precio} <span>MXN</span></div>	
							</div>
							<div className='col s3 m-p-0 prod-cont-aling'>
								{ producto.stock>0?
								<div onClick={(e)=>{e.preventDefault();_agregarAlCarrito()}}>
									<div className='primarycolor prod-car-bg'>
										<img className='icon-cart' alt='' />
									</div>
								</div>:
								<div className='btn prod-car-bg disabled'>
									<img className='icon-cart-2' alt='' />
								</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductCard;
