import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'
import { DataContext } from '../../context/DataContext'
import M from 'materialize-css'
import Nav from '../nav'
import StatusBar from '../compra/statusBar'
import './carrito.css'
import axios from '../../axios'

const Carrito = () => {

	const { carrito, setCarrito } = useContext(DataContext)
	const history = useHistory()
	const [total,setTotal] = useState(0)

	useEffect(()=>{

		const codigos=carrito.map(c=>c.codigo)
		axios.post('/api/productos',{codigos})
		.then((r)=>{
			//actualizar stock
			var carritoUpdate = carrito.map(c=>({...c, stock:r.data.find((cc)=>cc.codigo===c.codigo).stock, minimo_compra: c.stock<c.minimo_compra?c.stock:c.minimo_compra}))
			carritoUpdate = carritoUpdate.map(c=>({...c, cantidad: (c.cantidad>c.stock&&c.cantidad>=c.minimo_compra) ?c.stock:c.cantidad, selected:true}))

			if(carritoUpdate.some(c=>c.stock===0))alert('algunos elementos de tu carrito se han agotado')
			setCarrito(carritoUpdate.filter(c=>c.stock>0))
		})
		.catch((r)=>alert(r))


		setTotal(parseFloat(carrito.reduce((a,b)=>a+(parseInt(b.cantidad)*parseFloat(b.precio)),0).toFixed(2)))
		M.FormSelect.init(document.querySelectorAll('select'), {});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	useEffect(()=>{
		setTotal(parseFloat(carrito.reduce((a,b)=>b.selected?a+(parseInt(b.cantidad)*parseFloat(b.precio)):a,0).toFixed(2)))
		console.log(carrito)
	},[carrito])

	/**
	  * @param {Object} obj - product object {...Product}
	  */
	const _delete = (obj) => {
		setCarrito(carrito.filter(c=>c.id!==obj.id))
	}

	/**
	  * @param {Object} obj - product object {...Product}
	  */
	const _comprarItem = (obj) => {
		setCarrito([obj])
		history.push('/micarrito/direccion')
	}

	const _decrementarItem = (e,c) => {
		setCarrito(carrito.map(ca=>ca.id===c.id?{...ca,cantidad:parseInt((c.cantidad > c.minimo_compra)? c.cantidad-1:c.cantidad)}:ca))
	}

	const _incrementarItem = (e,c) => {
		setCarrito(carrito.map(ca=>ca.id===c.id?{...ca,cantidad:parseInt((c.cantidad < c.stock)? c.cantidad+1:c.stock)}:ca))
	}

	const _comprarArticulosSeleccionados = () => {
		setCarrito(carrito.filter(c=>c.selected))
		history.push('/micarrito/direccion')
	}

	return(
		<div className="" style={{backgroundColor:'#F5F5F5', height:'100%!important'}}>
			<Nav />
			<div style={{backgroundColor:'#ffffff',}}>
				<StatusBar status='carrito' />
			</div>
			<div className="cart-container" style={{height:'100%!important', minHeight:'62vh',}}>
				<div style={{paddingTop:'30px', justifyContent:'center',}}>
					<div className="container-mediumi white">
						<div className='show-on-640' style={{padding:20,fontSize:24, position:'relative', display:'none'}}>Subtotal:<div style={{display:'inline-block', fontWeight:'bold'}}> ${total}<label style={{position:'absolute',top:20,color:'black'}}>00</label></div></div>
						<Link to='/micarrito/direccion' style={{display:'flex', background:'#FED813',alignItems:'center',justifyContent:'center', height:40, borderRadius:10, margin:10, display:'none'}} className='btn waves-effect waves-light black-text bold show-on-640'>Proceder al pago</Link>
					{carrito.length===0&&
					<div style={{height:'10vh'}}>
						<h4 className="center-align">Tu carrito esta vacío</h4>
					</div>
					}
						
					{carrito.filter((c)=>document.documentElement.clientWidth<640?c.selected:true).map((c,i)=>
						<div key={i} className='row cart-product-card'>
							<div className='col m1 center m-p-0 hide-on-640'>
							<p>
								<label>
									<input type="checkbox" checked={c.selected} onChange={()=>{setCarrito(carrito.map(cc=>cc.codigo===c.codigo?{...cc, selected:!cc.selected}:cc))}}/>
									<span></span>
								</label>
							</p>
							</div>
							<div className='col m5 center m-p-0'>
								<Link to={`/product/${c.codigo}`}>
									<div className="cart-img-prod-cont"> 
										<img alt='' className="cart-img-prod-cont" src={c.imagenes.length===0?'https://bitsofco.de/content/images/2018/12/broken-1.png':c.imagenes[0].url}/>
									</div>
								</Link>
							</div>
							<div className='col m6 m-p-0'>
								<div className="cart-prod-info">
									<a href='#!' className='right grey-text hide-on-640' onClick={()=>_delete(c)}><i className="material-icons">delete</i></a>
									<Link to={`/product/${c.codigo}`}>
										<h4>{c.nombre}</h4>
									</Link>
									<p className="cart-prod-descripcion hide-on-640">{c.descripcion?c.descripcion:'Sin Descripción'}</p>
									<div className='show-on-640' style={{display:'none'}}>
									<div style={{color:'green'}}>Disponible</div>
									<div style={{fontSize:24, fontWeight:'bold'}}>${c.precio}<label style={{position:'relative',top:-5}}>00</label></div>
									</div>
									{/*
									<div style={{width:160}}>
										<select value={c.cantidad} onChange={(e)=>setCarrito(carrito.map(ca=>ca.id===c.id?{...ca,cantidad:parseInt(e.target.value)}:ca))}>
										{[...Array(c.stock)].map((el, i) =>
											<option value={i+1} key={i}>Cantidad {i+1}</option>
										)}
										</select>
										<label>Cantidad</label>
									</div>*/}
									<h4 className='hide-on-640'><b style={{fontSize:'10px'}}>Stock</b> {c.stock}</h4>
									<div className="row m-p-0 hide-on-640" style={{background:'#ffffff', borderRadius:'6px', height:'30px',width:'160px'}}>
										<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px',}}>
											<div onClick={(e)=>_decrementarItem(e,c)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> - </div>
										</div>
										<div className='col m4' style={{textAlign:'center', padding:'5px 5px 5px 5px',}}>
											{c.cantidad}
										</div>
										<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px',}}>
											<div onClick={(e)=>_incrementarItem(e,c)}
											style={{fontWeight:'800', fontSize:'20px', cursor:'pointer'}}> + </div>
										</div>
									</div>

									<div className="orange-brick-cart hide-on-640">Total: ${parseFloat((c.precio*c.cantidad).toFixed(2))} MXN</div>
									<a href='#!' className='btn waves-effect waves-light primarycolor cart-prod-btn hide-on-640' onClick={()=>_comprarItem(c)}> Comprar </a>
								</div>
							</div>

							<div className='col s12'>
							<div className='row show-on-640' style={{display:'none'}}>
								<div className='col s6 m-p-0'>
									<div style={{display:'inline-block',background:'#ECEDF0', borderRadius:'6px', border:'1px solid #D3D7CF'}}>
										<div className='col m4 m-p-0' style={{textAlign:'center',width:40}}>
											<div onClick={(e)=>_decrementarItem(e,c)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer', marginTop:4}}> - </div>
										</div>
										<div className='col m4 bold' style={{borderLeft:'1px solid #D3D7CF', borderRight:'1px solid #D3D7CF',fontSize:18,textAlign:'center', padding:'5px 5px 5px 5px',background:'white', color:'#418C99', width:40}}>
											{c.cantidad}
										</div>
										<div className='col m4 m-p-0' style={{textAlign:'center', padding:'5px', width:40}}>
											<div onClick={(e)=>_incrementarItem(e,c)} style={{fontWeight:'800', fontSize:'20px', cursor:'pointer',marginTop:4}}> + </div>
										</div>
									</div>
								</div>
								<div className='col s6 m-p-0'>
									<a href='#!' style={{borderRadius:5, background:'#F0F2F1', border:'2px solid #888A85', textTransform:'none'}} className='btn black-text' onClick={()=>_delete(c)}>Eliminar</a>
								</div>
								<div className='col s12 m-p-0'>
									<a href='#!' style={{borderRadius:5, background:'#F0F2F1', border:'2px solid #888A85', textTransform:'none'}} className='btn black-text' onClick={()=>setCarrito(carrito.map(cc=>cc.codigo===c.codigo?{...cc, selected:!cc.selected}:cc))}>Guardar para más tarde</a>
								</div>
							</div>
							</div>
						</div>
					)}
					{(carrito.length > 0) &&
						<div className="cart-product-card margin-bottom-50 hide-on-640" style={{textAlign:"right"}}>
							<div className="orange-brick-cart">Total: ${total} MXN</div>
							<Link to='/micarrito/direccion' className='btn waves-effect waves-light black cart-prod-btn-black'>Comprar articulos seleccionados</Link>
						</div>
					}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Carrito
