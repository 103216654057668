import React, {useContext } from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { DataContext } from '../../context/DataContext';

const Footer2 = () => {

	const  {config} = useContext(DataContext)
	const currentTime = new Date();
	const year = currentTime.getFullYear()

	return (
		<footer className='page-footer footerbackground infocolor-background'>
			<div className='footer-center'>
				<div className='row pt-40'>
					<div className='col cont-footer-link footer2-movil-col'>
						<div className='footerTitle footertext'>Servicio al cliente</div>
						<ul className='m-p-0'>
							<li>
								<p className='footertext footertext'>Envíanos un correo a:</p>
								<a className='footerLink footertext' href={'mailto://'+config.emailserviciocliente}>{config.emailserviciocliente}</a>
								<p className='footertext footertext'>
								{config.configs &&
									<>
									{config.configs.horario.diaInicio} a&nbsp; 
									{config.configs.horario.diaFin}:&nbsp; 
									{config.configs.horario.horaInicio}&nbsp;am a&nbsp; 
									{config.configs.horario.horaFin}&nbsp;pm
									</>
								}
								</p>
							</li>
						</ul>
					</div>
					<div className='es-movil' style={{scrollPadding:'10px 0',width:'100%!important',}}>
						{((config.configs.visible_redes.facebook===1&&config.url_facebook)||(config.configs.visible_redes.instagram===1&&config.url_instagram)||(config.configs.visible_redes.youtube===1&&config.url_youtube))&&
						<div className='footerTitle red-center-red footertext'>Redes Sociales</div>
						}
						{(config.configs.visible_redes.facebook===1&&config.url_facebook)&&
						<div className="col s4">
							<a href={config.url_facebook.includes('://')?config.url_facebook: `https://${config.url_facebook}`} className="red-right" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_facebook} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Facebook</span>
							</a>
						</div>
						}
						{(config.configs.visible_redes.instagram===1&&config.url_instagram)&&
						<div className="col s4">
							<a href={config.url_instagram.includes('://')?config.url_instagram: `https://${config.url_instagram}`} className="red-center" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_instagram} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Instagram </span>
							</a>
						</div>
						}
						{(config.configs.visible_redes.youtube===1&&config.url_youtube)&&
						<div className="col s4">
							<a href={config.url_youtube.includes('://')?config.url_youtube: `https://${config.url_youtube}`} className="red-center" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_youtube} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Youtube </span>
							</a>
						</div>
						}
					</div>
					<div className='col cont-footer-link footer2-movil-col'>
						<div className='footerTitle store-name footertext'></div>
						<ul className='m-p-0'>
							<li><Link className='footerLink footertext' to='/somos'>¿Quiénes somos?</Link></li>
							<li><Link className='footerLink footertext' to='/aviso'>Aviso de privacidad</Link></li>
							<li><Link className="footerLink footertext" to='/bolsa_trabajo'>Trabaja con nosotros</Link></li>
						</ul>
					</div>
					<div className='col cont-footer-link footer2-movil-col'>
						<div className='footerTitle footertext'>Para Clientes</div>
						<ul className='m-p-0'>
							<li><Link className='footerLink footertext' to='/FAQ'>FAQ</Link></li>
							<li><Link className='footerLink footertext' to='/glosario'>Glosario</Link></li>
							<li><Link className="footerLink footertext" to='/pagos_envios'>Pagos y envíos</Link></li>
							<li><Link className="footerLink footertext" to='/politicas_compras'>Políticas de compra</Link></li>
						</ul>
					</div>
					<div className='col cont-footer-link es-pc footer2-movil-col'>
						{((config.configs.visible_redes.facebook===1&&config.url_facebook)||(config.configs.visible_redes.instagram===1&&config.url_instagram)||(config.configs.visible_redes.youtube===1&&config.url_youtube))&&
						<div className='footerTitle'>Redes Sociales</div>
						}
						<ul className='m-p-0'>
							{(config.configs.visible_redes.facebook===1&&config.url_facebook)&&
							<li>
								<Link className="footerLink footertext" target="_blank" to={{pathname:config.url_facebook.includes('://')?config.url_facebook: `https://${config.url_facebook}`}} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_facebook} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Facebook </span>
								</Link>
							</li>
							}
							{(config.configs.visible_redes.instagram===1&&config.url_instagram)&&
							<li>
								<Link className="footerLink footertext" target="_blank" to={{pathname:config.url_instagram.includes('://')?config.url_instagram: `https://${config.url_instagram}`}} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_instagram} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Instagram </span>
								</Link>
							</li>
							}
							{(config.configs.visible_redes.youtube===1&&config.url_youtube)&&
							<li>
								<Link className="footerLink footertext" target="_blank" to={{pathname:config.url_youtube.includes('://')?config.url_youtube: `https://${config.url_youtube}`}} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_youtube} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Youtube </span>
								</Link>
							</li>
							}
						</ul>
					</div>
				</div>
			</div>
			<div className='footer-copyright  footertext footer-inferior'>
				<div className='container' style={{textAlign:'center', fontSize:'11px'}}>
					<div className='store-name' style={{fontWeight:'bold', fontSize:'14px'}}></div>
					© {year} <span className='store-name'></span> México. Todos los derechos reservados 
				</div>
			</div>
		</footer>
    );
};

export default Footer2;
