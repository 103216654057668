import React from 'react';

const Titulo = () => {
    return (
        
        <div className="section-title-parent">
            <div className="section-title-child primarycolor"></div>
                <div className='nombre-seccion-principal section-tittle'></div>
            <div className="section-title-child primarycolor"></div>
        </div>
       
    );
};

export default Titulo;