import React, {useState, useEffect} from 'react';
import './detallePedidos.css'
import Nav from '../nav';
import axios from 'axios';
import Loader from '../Loader'
import { useParams } from 'react-router';


const DetallePedidos = () => {

	const { pedido_id } = useParams();
	const [pedido,setPedido] = useState(false)
	const [loader, setLoader] = useState(true)

	useEffect(()=>{
		axios.get(`/api/pedido/${pedido_id}`).then(r=>{
			setPedido(r.data)
		setLoader(false)
		}).catch(r=>alert(r))
	},[pedido_id])

	if(loader) return <Loader />

	return (
		<div>
			<Nav />
			<div className='row m-p-0'>
				<div className='col s12 m-p-0 barra'>
					<div>Detalles del pedido:</div>
				</div>
			</div>

			<div className="cont-result bg-container">
				<div className="container-medium">
					<div className="col s12 margin-top-50">
						<div className="pedido-subtitle">SEGUIR PEDIDO<span> ( DETALLES DEL PEDIDO ) </span></div>
						<div className="hr-subtitle"></div>
					</div>
					<div className='row' style={{marginBottom:"100px"}}>
						<div className="pad-10-0 container-tabs" >
							<div className="tabs-info">
								<div className="row m-p-0 bloque1">
									<div className='col s12 m3 m-p-0'>
										<div className="text negro"> PEDIDO-{String(pedido.id).padStart(4,0)} </div>
										<div className="text">
											HECHO:
											<div className="textos">{pedido.fecha}<span className="status"> ({pedido.status}) </span></div>
										</div>
									</div>
									<div className='col s12 m2 m-p-0 text'>
										TOTAL:
										<div className="textos">${pedido.total} MXN</div>
									</div>
									<div className='col s12 m2 m-p-0 text'>
										ENVIADO A:
										<div className="destinatario">{pedido.envio.nombre}</div>
									</div>
								</div>
							</div>
							<div className="row white">
								<div className="col s12 m6 dir-container">
									<div className="pedido-subtitle">Datos de envío:</div>
									<div className="bloque">
										<div className="titulo-bloque"> Enviar a: {pedido.envio.nombre} </div>
										<div className="text-bloque"> 
											{pedido.envio.calle} #{pedido.envio.numExt} {pedido.envio.numInt?`Interior: ${pedido.envio.numInt}`:''}, {pedido.envio.colonia}, C.P. {pedido.envio.cp} {pedido.envio.ciudad},{pedido.envio.estado}.
										</div>
									</div>
									<div className="bloque">
										<div className="titulo-bloque">Número de Teléfono:</div>
										<div className="text-bloque">{pedido.envio.telefono}</div>
									</div>
									<div className="bloque">
										<div className="titulo-bloque">Tipo de envío</div>
										<div className="text-bloque">{pedido.envio.descripcion}</div>
										<div className="guia-bloque">Número guía: {pedido.envio.trackingNumber}</div>
										<div className="text-bloque">Precio: ${pedido.envio.precio} MXN</div>
									</div>
								</div>
								<div className="col s12 m6 dir-container margin-bottom-20" style={{position:"relative"}}>
									<div className="vertical-divider"></div>
									<div className="pedido-subtitle">Resumen del pedido:</div>
									<div className="producto-bloque-container">
										{pedido.articulos.map((item,i)=>
											<div className="producto-bloque" key={i}>
												<div className="row">
													<div className="col s3 m3 l2" style={{height:"70px"}}>
														<img src={(item.urls.length > 0) ? item.urls[0]:'https://bitsofco.de/content/images/2018/12/broken-1.png'} alt='' 
														style={{height:"70px",}} />
													</div>
													<div className="col s9 m9 l10">
														<div className="titulo">{item.nombre}</div>
														<div className="texto">Cantidad {item.cantidad}</div>
														<div className="precio">Total ${parseFloat(item.precio * item.cantidad).toFixed(2)} MXN</div>
													</div>
												</div>
											</div>
										)}
									</div>
									<div className="pedido-totals">
										<div className="row">
											<div className="col s4 texto-bloque bold">
												<div>Subtotal:</div>
												<div>FedEx Exprés:</div>
												<div className="orange-brick margin-top-5">Total:</div>
											</div>
											<div className="col s8 texto-bloque bold">
												<div>${pedido.subtotal} MXN</div>
												<div>${pedido.envio.precio} MXN</div>
												<div className="orange-brick margin-top-5">${pedido.total} MXN</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col s12">
									<div className="divider"></div>
								</div>
								<div className="col s12">
									<div className="orange-brick bold margin-top-20 padding-0">Fecha de entrega estimada: {pedido.envio.entrega}</div>
									<div className="text row" style={{padding:"20px 0"}}>
										<div className='col m2 m-p-0 font-12'>
											<div className="cont-icon cont-icon-activo"><i className="tiny material-icons m-p-0">check</i></div>{pedido.fecha}
										</div>
										<div className='col m2 m-p-0 font-12'>
											<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}><i className="tiny material-icons m-p-0">check</i></div>Enviado
										</div>
										{pedido.envio.status.CompletedTrackDetails.TrackDetails.Events&&
										<div className='col m5 m-p-0 font-12'>
											<div className={(['Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> <i className="tiny material-icons m-p-0">check</i></div>En camino
											{(['Enviado','Entregado'].includes(pedido.status))&&
											<div style={{marginTop:20}}>
											{pedido.envio.status.CompletedTrackDetails.TrackDetails.Events.length>0?pedido.envio.status.CompletedTrackDetails.TrackDetails.Events.map((e,i)=>
												<div key={i} className='row' style={{fontSize:16, margin:5}}>
													<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
													<div className='col'>{new Date(e.Timestamp).toLocaleString()}</div>
													<div className='col s3'>{e.Address.City}</div>
													<div className='col'>{e.EventDescription}</div>
												</div>
											):
												<div className='row' style={{fontSize:16, margin:5}}>
													<div className='col'><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i></div>
													<div className='col'>{new Date(pedido.envio.status.CompletedTrackDetails.TrackDetails.Events.Timestamp).toLocaleString()}</div>
													<div className='col s3'>{pedido.envio.status.CompletedTrackDetails.TrackDetails.Events.Address.City}</div>
													<div className='col'>{pedido.envio.status.CompletedTrackDetails.TrackDetails.Events.EventDescription}</div>
												</div>
											
											}
											</div>
											}
										</div>
										}
										<div className='col m2 m-p-0 font-12'>
											<div className={(['Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> <i className="tiny material-icons m-p-0">check</i></div>Entregado
											{(['Entregado'].includes(pedido.status))&&
											<div style={{marginTop:20}}><i className="tiny material-icons" style={{background:'#4D4D4D', color:'white', borderRadius:50}}>check</i> {pedido.envio.status.CompletedTrackDetails.TrackDetails.DeliverySignatureName}</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetallePedidos;
