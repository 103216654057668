import React, { Fragment } from 'react';
import Nav from '../nav';

const Cupones = () => {
    return (
       <Fragment>
           <Nav/>
            <div className="col s12 padding-10 bg-perfil">
                <div className="container white padding-20 borde z-depth-1 margin-top-50 margin-bottom-50">
                    <div className="row">
                        <div className="col s12">Escribe tu cupón o promo</div>
                        <div className="input-field col s7  m4 padding-r-0">
                            <input id="first_name" type="text" className="validate" placeholder=" 33sdf2dDA3"/>
                        </div> 
                        <div className="input-field col s3 m2 padding-0 codigo-txt">
                            <button className="btn waves-effect waves-light btn-agregar-sm">Agregar</button>
                        </div>

                        <div className="col s12">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th>Lista de cupónes:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="codigo-th">LORD243242</td>
                                        <td className="status-th success-green">
                                            Agregado exitosamente  <i className="tiny material-icons ">check</i>
                                        </td>
                                        <td className="right-align">
                                            <button className="btn btn-blue waves-effect waves-light">Aplicar Cupón</button> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="codigo-th">LORD243242</td>
                                        <td className="status-th info-blue">
                                            Agregado exitosamente  <i className="tiny material-icons ">check</i>
                                        </td>
                                        <td className="right-align">
                                            <button className="btn waves-effect waves-light btn-transparent">Quitar</button> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="codigo-th">LORD243242</td>
                                        <td className="status-th">
                                        
                                        </td>
                                        <td className="right-align">
                                            <button className="btn waves-effect waves-light btn-agregar">Agregar Cupón</button> 
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="col s12 margin-y-50">
                            <table className="highlight">
                                <thead>
                                    <tr>
                                        <th>Lista de promociones:</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="codigo-th">LORD243242</td>
                                        <td className="status-th success-green">
                                          
                                        </td>
                                       
                                    </tr>
                                    <tr>
                                        <td className="codigo-th">LORD243242</td>
                                        <td className="status-th info-blue">
                                            Agregado exitosamente  <i className="tiny material-icons ">check</i>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment> 
    );
};

export default Cupones;
