import React, { useState } from 'react';
import './recuperar.css';
import Nav from '../nav';
import axios from '../../axios'


const Recupear = () => {

	const [data, setData] = useState({email:''}); 
	const [message, setMessage] = useState('')

	const _push = (e) => {
		e.preventDefault()
		axios.post('/api/password/create', data)
		.then(r=>{
			setMessage(r.data.message)
		})
		.catch(r=>alert(r))

	}

	return (
	<>
		<Nav />
		<div className='row m-p-0 height80 center-y-x'>
			<div className='col s12 m6 m-p-0 center-y-x'>
				<div className='card-panel'>
					{message ===''?
					<>
						<div className='s12 center-y-x'>
							<p>Te enviaremos un correo para recuperar tu contraseña</p>
						</div>
						<div className='col s12 pt-10'>
							<form onSubmit={_push}>
								<div className='input-field col s12 p-0'>
									<input onChange={(e)=>setData({...data, email: e.target.value})} id='email' type='email' className='input-height35 validate'/>
									<label htmlFor='email' style={{width:'auto'}}>Correo Electronico*</label>
								</div>
								<button className='btn btn primarycolor white-text waves-effect waves-light col s12' >Enviar</button>
							</form>
						</div>
					</>:
					<p>{message}</p>
					}
				</div>
			</div>
		</div>
	</>
	);
};

export default Recupear;
