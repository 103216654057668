import React from 'react';
import { Link } from 'react-router-dom'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Grid } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "swiper/css/grid";
import ProductCard from '../ProductCard/ProductCard';


const Nuevos = (props) => {

	const { productos } = props
	console.log(document.documentElement.clientWidth)

	return (
		<div className='col s12 m-p-0' tabIndex="0" style={{margin:'0!important', padding:'0!important', marginLeft:'100px!important'}}>
			<Swiper className="" style={{borderRadius:'12px', margin:'0!important', padding:'0!important',}}
				modules={[Navigation, Pagination, Scrollbar, A11y, Grid]}
				loop={false}
				slidesPerView={document.documentElement.clientWidth<640?2:'auto'}
				navigation={document.documentElement.clientWidth<1024?false:true}
				scrollbar={document.documentElement.clientWidth<1024?{draggable:true}:false}
				grid={{ rows: document.documentElement.clientWidth<640?2:1, fill:'row' }}
				>
				{productos.map((p,i)=>
					<SwiperSlide id="carusel-elementos" key={i} tabIndex="0" style={{borderRadius:'12px', margin:'0!important', padding:'0!important',width:'240px'}}>
						<Link to={`/product/${p.codigo}`} tabIndex="-1" style={{margin:'0!important', padding:'0!important', width:'100%', display:'block'}}>
							<ProductCard producto={p}/>
						</Link>
					</SwiperSlide>
				)}
			</Swiper>
		</div>
	);
};

export default Nuevos;
