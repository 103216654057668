import React from 'react';

const Loader = () => {

	return (
		<div className="loader-container">
			<div className="progress">
				<div className="indeterminate primarycolor"></div>
			</div>
			<div className="loader-content" style={{minHeight:'35vh', position:'relative!important', backgroundColor:'#ffffff',zIndex:'99999'}}>
				<span className="loader"></span>
				<span className="loader-text">Cargando..</span>
			</div>   
		</div>
	);
};

export default Loader;
