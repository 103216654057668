import React, {useState, useEffect} from 'react';
import Vistos from '../Vistos/Vistos';
import Nuevos from './Nuevos';
import axios from '../../axios'

const Productos = () => {

	const [nuevos, setNuevos] = useState([])
	const [vendidos, setVendidos] = useState([])

	useEffect(() => {
		axios.get('/api/catalogonuevos')
		.then(r=>{
			setNuevos(r.data);
		})
		.catch(r=>alert(r))

		axios.get('/api/catalogomasvendidos')
		.then(r=>{
			setVendidos(r.data.filter(item=>item.id));
		})
		.catch(r=>alert(r))
	},[])

	return (
	<div className="row" style={{backgroundColor:'#E5E5E5', margin:0, padding:'50px 15px',}}>
		<div className='row' style={{margin:'0 auto', padding:0, maxWidth:'1440px',}}>
			<div className="col s12 m6 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Lo más vendido </div>
					<div className='row m-p-0 center-y-x' style={{margin:'0!important', padding:'0!important',}}> 
						{/* Lo más vendido */}
						<Nuevos productos={vendidos} />
					</div>  
				</div>
			</div>
			<div className="col s12 m6 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Nuevo </div>
					<div className='row m-p-0 center-y-x' style={{margin:'0!important', padding:'0!important',}}> 
						{/* VIstos recientemente */}
						<Nuevos productos={nuevos} />
					</div>  
				</div>
			</div>
			<div className="col s12 m12 container-prod">
				<div className="container-blanco"> 
					<div className="source-sans nombre-clasificacion"> Vistos recientemente </div>
					<div className='row m-p-0 center-y-x' style={{margin:'0!important', padding:'0!important',}}> 
						{/* VIstos recientemente */}
						<Vistos />
					</div> 
				</div>
			</div>
		</div>
	</div>
	);
};

export default Productos;
