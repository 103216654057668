import React from "react";
import 'materialize-css'
import { DataProvider } from './context/DataContext'
import Dashboard from './components/dashboard'

const App = () => {

	return (
		<DataProvider>
			<Dashboard />
		</DataProvider>
	);

}

export default App
