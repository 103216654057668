import React, {useEffect, useState} from 'react';
import axios from '../../../axios';
import Nav from '../../nav';
import './glosario.css';

const Glosario = () => {
    const [palabras, setGlosario] = useState([]);
    useEffect(()=>{
        axios.get(`/api/glosario`)
		.then(r=>{
			setGlosario(r.data)
		})
		.catch(r=>alert(r))
    },[])
    return (
        <div>
            <Nav />
            <div className='row'>
                <div className='col s12 m-p-0 brad'>
                    <div>Glosario</div>
                </div>
            </div>
            <div className="cont-result">
                <div className='row center-y-x'>
                    <div className='col s12 m-p-0'>
                        
                        {/*
                        Este bloque de codigo contiene el esqueleto de un boton
                        para si se colocara el abecedario y filtrar por letra.
                        <div className='m-p-0 center-y-x'>
                            <div className='m-p-0 center-y-x cont-btn'>
                                <button type="button" className="center-y-x m-0 btn-letra">
                                    <div className="center-y-x letra"> A </div>
                                </button>
                            </div>
                        </div>*/}
                        <div>

                            {palabras.map((dic,i)=>
                                <div key={i} className="m-0 center-y-x" style={{width:'100%',padding:0,paddingBottom:'8px',}}>
                                    <div className="palabra"> {dic.palabra} .- &nbsp;&nbsp; </div>  
                                    <div className="definicion"> {dic.definicion} </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Glosario;
