import React, { useContext, useState } from 'react';
import {Switch, Route ,Link, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../../context/DataContext'
import Nav from '../nav';
import "./perfil.css";
import Direcciones from '../Direcciones/Direcciones';
import MiPerfil from '../MiPerfil/MiPerfil';

const Perfil = () => {

	const history = useHistory();
	const location = useLocation();
	const { from } = location.state || { from: { pathname: "/" } };
	const { user, setUser } = useContext(DataContext)
	let { path, url } = useRouteMatch();
	const [data, setData] = useState({img:''})

	const logout = () =>{
		axios.delete('/api/logout')
		.then(res => {
			setUser({})
			history.replace(from)
		}).catch(e=>alert(e))
	}

	/**
	  * @param {File} img - img input select
	  */
	const _imgUpload = (img) => {
		const fData = new FormData();
		fData.append("img", img);
		axios.post('/api/imgperfil',fData)
		.then(r=>{
			const picture=document.querySelector('#src-picture')
			picture.src=`${axios.defaults.baseURL}/users/${user.id}.png#${new Date().getTime()}`
		})
		.catch(r=>alert(r))
	}

	return (
		<>
			<Nav />
			<div style={{height:"54px",background:"#FCFCFC",paddingLeft:38,paddingTop:18,fontSize:"16px",fontWeight:500}}>
				Mi cuenta
			</div>
			<div className="col s12 bg-perfil">
				<div className="container-medium margin-cont white padding-20 borde">
					<div className="perfil-header">
						<div className="row margin-left-0">
							<div className="col perfil-img-cont" style={{margin:0}}>
								<div className="perfil-picture" style={{position:'relative',height:"211px"}}>
									<img id='src-picture' alt='user' className="responsive-img perfil-img" src={`${axios.defaults.baseURL}/users/${user.id}.png`} style={{height:"100%"}} onError={(e)=>{e.target.onerror = null; e.target.src="../assets/default-user-icon-13.jpg"}}/>
									<label style={{cursor:'pointer', position:'absolute',right:10,top:10}}>
										<i className="material-icons black-text small edit-perfil-icon">edit</i>
										<input type="file"  style={{display:'none'}} onChange={e=>_imgUpload(e.target.files[0])} accept="image/png, image/gif, image/jpeg"/>
									</label>
								</div>
							</div>
							<div className="col user-info-container">
								<div className="black-text">
									<p>Nickname: {user.name}</p>
									<p>Email: {user.email}</p>
									{user.telefono && 
									<p>Telefono:  {user.telefono}</p>
									}
									<Link style={{width:"100%"}}  to={`${url}/datos`}>
									<i className="material-icons perfil-icon">create</i>
								</Link>
								</div>
							</div>
						</div>
					</div>
				</div>		
				{/*
				<div className="container-medium white">
					<div className="row padding-10" style={{margin:0}}>
						<div className="col s12 m4 padding-20">
							<div className="tab-btn tab-btn-right">
								<span>USUARIO</span>
								<div className="user-mgs"> 10 </div>
								<img  style={{position:"absolute",right:16,top:10,height:10}} src="../assets/icon-star.png" alt="start" />
								<img  style={{position:"absolute",right:20,height:14}} src="../assets/icon-star.png" alt="start" />
								<img  style={{position:"absolute",right:16,bottom:10, height:10}} src="../assets/icon-star.png" alt="start" />

							</div>
						</div>	
						<div className="col s12 m4 padding-20" >
							<div className="tab-btn" style={{margin:"auto"}}>
								<span>MI LISTA DE DESEOS</span>
								<img  style={{position:"absolute",right:20,height:23}} src="../assets/icon-cora.png" alt="lista deseos" />
							</div>
							
						</div>				
						<div className="col s12 m4 padding-20">
							<div className="tab-btn tab-btn-left">
								<span>MI DINERO DIGITAL</span>
								<i className="material-icons money-icon">attach_money</i>
							</div>
						</div>	

					</div>
				</div>					
				*/}
				<div className="container-medium white">	
					<div className="row padding-10 borde" style={{minHeight:"400px",margin:0}}>
						<div className="col s12 m3">
							{/*
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}} to={`${url}`}>
								<div className="col s9">
									Mis cupones
								</div> 
								<div className="col s3">
									<i className="material-icons">chevron_right</i>
								</div>
								</Link>
							</div>*/
							}
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to="/pedidos">
									<div className="col s9">
									Mis pedidos
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to={`${url}/misdirecciones`}>
									<div className="col s9">
									Mis direcciones
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
							<div className="col s12 perfil-list">
								<Link style={{width:"100%"}}  to={`${url}/datos`}>
									<div className="col s9">
									Mi perfil
									{/*<Link to="/"> Mis direcciones </Link> */}
									</div> 
									<div className="col s3">
										<i className="material-icons">chevron_right</i>
									</div>
								</Link>
							</div>
						</div>
						<div className="col s12 m9" style={{height:"100%"}}>
							<Switch>
								<Route exact path={path} />
								<Route path={`${path}/misdirecciones`} component={Direcciones}/>
								<Route path={`${path}/datos`} component={MiPerfil}/>
							</Switch>
						</div>			   
					</div> 
				</div>
				<div className="container-medium white padding-10" style={{marginBottom:"50px"}}>
					<div className="row" style={{margin:0}}>
						<div onClick={logout} className="col s12 m4 logout-container padding-10">
							<img src="../assets/logout.png" style={{height:"19px"}} alt="exit" />
							<span style={{paddingLeft:"10px"}}>
								Cerrar Sesión
							</span>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};



export default Perfil;
