import React, {useEffect, useState} from 'react';
import axios from '../../../axios';
import './somos.css'
import Nav from '../../nav';

const Somos = () => {
    const [somos, setSomos] = useState('');
    useEffect(()=>{
        axios.get(`/api/quienessomos`)
		.then(r=>{
			setSomos(r.data)
		})
		.catch(r=>alert(r))
    },[])

    return (
        <div>
			<Nav />
            <div className='row'>
                <div className='col s12 m-p-0 brad'>
                    <div>Acerca de nosotros:</div>
                </div>
            </div>
            <div className="cont-result">
                
				<div className='row center-y-x'>
					<div className='col s10 m10 l8 m-p-0'>
                        <div className="somos">¿Quiénes somos?</div>
                        <div dangerouslySetInnerHTML={{__html: somos}} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Somos;