import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css'

const FilterNav = ({categorias=[], departamentos=[], productos=[], setProductos=null}) => {

	const [filtroPrecio, setFiltroPrecio] = useState('')

	useEffect(() => {
		M.Modal.init(document.querySelectorAll('.modal'), {endingTop:0});
	},[])

	const _showTab = (e, tabshow) => {
		const tablinks = document.getElementsByClassName("tablinks");
		for (var i = 0; i < tablinks.length; i++) {
			tablinks[i].style.background = 'none'
		}
		const tabcontent = document.getElementsByClassName("tabcontent");

		for (var i = 0; i < tabcontent.length; i++) {
			tabcontent[i].style.display = "none";
		}

		document.getElementById(tabshow).style.display = "block";
		e.currentTarget.style.background='white'
	}

	const _filtroPrecio = (filtro) => {
		setFiltroPrecio(filtro)
		console.log(filtro)
		if(filtro==='Precio bajo-alto')setProductos(productos.sort((a,b)=>a.precio<b.precio?-1:1))
		if(filtro==='Precio alto-bajo')setProductos(productos.sort((a,b)=>a.precio>b.precio?-1:1))
		if(filtro==='Más nuevo')setProductos(productos.sort((a,b)=>a.created_at>b.created_at?-1:1))
	}

	return(
		<div className='show-on-640' style={{position:'relative',boxShadow: '0px 0px 5px grey',display:'none', marginBottom:20}}>
			<div id="modal1" className="modal" style={{margin:0, width:'100%', maxHeight:'100%'}}>
				<div className="modal-content" style={{height:'100vh', padding:0}}>
					<div style={{position:'relative', padding:15, boxShadow: '0px 0px 3px rgba(0,0,0,.5)'}}>
						<div style={{fontSize:18}}>Filtros</div>
						<div style={{position:'absolute', right:0, top:0, padding:15}}>
							<a href="#!" className="modal-close" style={{color:'#236F7A', fontSize:18}}>Cerrar</a>
						</div>
					</div>
					<div style={{display:'flex', flexDirection:'row', height:'100%'}}>
						<div style={{background:'#F0F2F1',width:200}}>
							<a href='#!' className="tablinks" onClick={(e)=>_showTab(e,'tab-categorias')} style={{background:'white', display:'block', padding:10, color:'black'}}>Categorias</a>
							<a href='#!' className="tablinks" onClick={(e)=>_showTab(e,'tab-departamentos')} style={{display:'block', padding:10, color:'black'}}>Departamentos</a>
							<a href='#!' className="tablinks" onClick={(e)=>_showTab(e,'tab-ordenar')} style={{display:'block', padding:10, color:'black'}}>Ordenar por</a>
						</div>
						<div style={{width:'100%', padding:10}}>
							<div id="tab-categorias" className="tabcontent">
								{categorias.map(((c,i)=>
									<div key={i} style={{display:'inline-block',margin:10}}>
										<Link key={i} to={`/cat/${c.id}`} className="black-text" style={{position:'static',background:'#F4F4F4', padding:8, borderRadius:5}}>{c.categoria}</Link>
									</div>
								))}
							</div>
							<div id="tab-departamentos" className="tabcontent" style={{display:'none'}}>
								{departamentos.map(((d,i)=>
									<div key={i} style={{display:'inline-block',margin:10}}>
										<Link key={i} to={`/deps/${d.id}`} className="black-text modal-close" style={{position:'static',background:'#F4F4F4', padding:8, borderRadius:5}}>{d.departamento}</Link>
									</div>
								))}
							</div>
							<div id="tab-ordenar" className="tabcontent" style={{display:'none'}}>
								<form action="#">
									<p>
										<label>
											<input onChange={()=>_filtroPrecio('Precio bajo-alto')} name="group1" type="radio" checked={filtroPrecio==='Precio bajo-alto'?true:false}/>
											<span>Precio bajo-alto</span>
										</label>
									</p>
									<p>
										<label>
											<input onChange={()=>_filtroPrecio('Precio alto-bajo')} name="group1" type="radio" checked={filtroPrecio==='Precio alto-bajo'?true:false} />
											<span>Precio alto-bajo</span>
										</label>
									</p>
									<p>
										<label>
											<input onChange={()=>_filtroPrecio('Más nuevo')} name="group1" type="radio" checked={filtroPrecio==='Más nuevo'?true:false} />
											<span>Más nuevo</span>
										</label>
									</p>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div style={{overflow:'auto', width:'80vw'}}>
				<ul style={{whiteSpace:'nowrap'}}>
					{categorias.map(((c,i)=><li key={i} style={{display:'inline'}}><Link key={i} to={`/cat/${c.id}`} className="black-text" style={{background:'#F4F4F4', padding:8, margin:5, borderRadius:5}}>{c.categoria}</Link></li>))}
				</ul>
				
			</div>
			<a className='modal-trigger' href='#modal1' style={{position:'absolute', right:0, top:0, width:'20vw',height:'100%', padding:15, color:'#236F7A'}}>
				Filtros
			</a>
		</div>
	)
}

export default FilterNav
