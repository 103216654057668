import React from 'react';
import './compra.css'

/**
  * @param {String} status
  * @type {'carrito' | 'direccion' | 'envio' | 'pago' | 'fin'}
  */
const StatusBar = ({status}) => {

	const _load = () => {
		switch (status) {
			case 'carrito':
				return '20%'	
			case 'direccion':
				return '35%'	
			case 'envio':
				return '60%'	
			case 'pago':
				return '80%'	
			case 'fin':
				return '100%'	
			default:
				return '0%'
		}
	}

	return (
		<div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
			<div className='m-p-0' style={{width:800}}>
				<div className="tags m-p-0"  style={{width:'100%'}}>
					<a href='#!' className="tabMargin20" style={{color:status==='carrito'?'black':'#ABABAB'}}>CARRITO</a>
					<a href='#!' className="tabMargin20" style={{color:status==='direccion'?'black':'#ABABAB'}}>DIRECCIÓN</a>
					<a href='#!' className="tabMargin20" style={{color:status==='envio'?'black':'#ABABAB'}}>OPCIONES DE ENVÍO</a>
					<a href='#!' className="tabMargin20" style={{color:status==='tarjeta'?'black':'#ABABAB'}}>TARJETA - PAGO</a>
					<a href='#!' className="tabMargin20" style={{color:status==='fin'?'black':'#ABABAB'}}>FINALIZAR</a>
				</div>
				<div className={status==='fin'?'black-points':'points'}>
					<div className="progress">
						<div className="determinate black" style={{width:_load()}}></div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StatusBar
