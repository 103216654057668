import React, { useState, createContext, useEffect } from 'react'
import axios from '../axios'

export const DataContext = createContext()
export const DataProvider = props => {

	const [user, setUser] = useState({})
	const [carrito, _setCarrito] = useState([])
	const [buscados, _setBuscado] = useState([])
	const [vistos, _setVistos] = useState([])
	const [direccion, _setDireccion] = useState({})
	const [servicioEnvio, _setServicioEnvio] = useState({proveedor:'', servicio:'', precio:'',descripcion:'', entrega:''})
	const [config, _setConfig] = useState({})

	localStorage.getItem('carrito')
	
	useEffect(()=>{
		//localStorage.clear();
		_setCarrito(localStorage.getItem('carrito')===null?[]:JSON.parse(localStorage.getItem('carrito')))
		_setDireccion(localStorage.getItem('direccion')===null?{}:JSON.parse(localStorage.getItem('direccion')))
		_setServicioEnvio(localStorage.getItem('servicioEnvio')===null?{proveedor:'', servicio:'', precio:''}:JSON.parse(localStorage.getItem('servicioEnvio')))
		_setBuscado(localStorage.getItem('palabrasbuscadas')===null?[]:JSON.parse(localStorage.getItem('palabrasbuscadas')))
		_getVistos(localStorage.getItem('vistosrecientes')===null?[]:JSON.parse(localStorage.getItem('vistosrecientes')))		
		//_setVistos(localStorage.getItem('vistosrecientes')===null?[]:JSON.parse(localStorage.getItem('vistosrecientes')))
		_getConfig()
	},[])

	const _getVistos = (obj) =>{
		_setVistos(obj)
		
		let codigosStrings = obj.map(p=>p.codigo).join(',')
		
		axios.post('/api/getVistos', {codigosStrings})
		.then(r=>{
			if(r.data) _setVistos(r.data)
		})
		.catch(r=>alert(r))
	}

	const _getConfig = () =>{
		axios.get('/api/configwebsite').then(r=>{
			document.title = r.data.nombretienda
			_setConfig(r.data)
		}).catch(r=>alert(r))
	}

	/**
	  * @param [{Object}] obj - Carrito List Items objects
	  */
	const setCarrito=(obj)=>{
		localStorage.setItem('carrito', JSON.stringify(obj));
		_setCarrito(obj)
	}

	/**
	  * @param {Object} obj - Direccion
	  */
	const setDireccion=(obj)=>{
		localStorage.setItem('direccion', JSON.stringify(obj)==='{}'?null:JSON.stringify(obj));
		_setDireccion(obj)
	}

	/**
	  * @param {Object} obj - servicioEnvio {proveedor:'', servicio:'', precio:''}
	  */
	const setServicioEnvio=(obj)=>{
		localStorage.setItem('servicioEnvio', JSON.stringify(obj)==='{}'?JSON.stringify({proveedor:'', servicio:'', precio:''}):JSON.stringify(obj));
		_setServicioEnvio(obj)
	}

	/**
	  * @param [{Object}] obj - Buscar 
	  */
	 const setBuscados=(obj)=>{
		localStorage.setItem('palabrasbuscadas', JSON.stringify(obj));
		_setBuscado(obj)
	}
	/**
	  * @param [{Object}] obj - Vistos 
	  */
	 const setVistos=(obj)=>{
		localStorage.setItem('vistosrecientes', JSON.stringify(obj));
		_getVistos(obj)
	}

	return (
		<DataContext.Provider value={{user, setUser, carrito, setCarrito, direccion, setDireccion, servicioEnvio, setServicioEnvio, buscados, setBuscados, vistos, setVistos, config}}>
			{props.children}
		</DataContext.Provider>
	)

}
