import React, {useEffect, useState} from 'react';
import axios from '../../../axios';
import Nav from '../../nav';
import './faq.css';
import M from 'materialize-css'

const FAQ = () => {
	
	const [faq, setFaq] = useState([]);
	
	useEffect(()=>{
		
		M.Collapsible.init(document.querySelectorAll('.collapsible'), {}); 
		
		axios.get(`/api/faq`)
		.then(r=>{
			setFaq(r.data)
		})
		.catch(r=>alert(r))
		
	},[])



    return (
        <div>

            <Nav />
            <div className='row'>
                <div className='col s12 m-p-0 brad'>
                    <div style={{marginLeft:"40px"}}>FAQ</div>
                </div>
            </div>
            <div className="cont-result">
				<div className='row center-y-x'>
					<div className='col s12 m10 l10 m-p-0'>
                        <div className="somos"> Preguntas Frecuentes</div>
                       
                        <div>
                            <ul className="collapsible" id="acordeon">
                            {faq.map((item,i)=>
                                <li key={i}>
                                    <div className="collapsible-header"><i className="material-icons">question_answer</i>
                                        {item.pregunta}
                                    </div>
                                    <div className="collapsible-body"  style={{fontWeight:'100!important'}}>
                                        <p className="respuesta"> {item.respuesta} </p>
                                    </div>
                                </li>
                            )}
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default FAQ;
